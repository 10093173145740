import React, { createContext, useEffect, useState } from 'react';

export const OnlineOrderContext = createContext();

const OnlineOrderProvider = ({ children }) => {
    const [orderType, setOrderType] = useState('');
    const [orderData, setOrderData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        note: '',
        dateTime: new Date(),
        orderType: orderType === "online" ? "online" : "takeAway",
    });

    useEffect(() => {
        console.log("Order data changed", orderData);
        console.log("Order type on OnlineNewOrder.js changed", orderType);
    }, [orderData, orderType]);

    const updateOrderData = (data, type) => {
        setOrderData(data);
        setOrderType(type);
    };

    const resetOnlineOrder = () => {
        setOrderType('');
        setOrderData({
            firstName: '',
            lastName: '',
            phoneNumber: '',
            note: '',
            dateTime: new Date(),
            orderType: 'takeAway',
        });
    };

    return (
        <OnlineOrderContext.Provider value={{ orderData, orderType, updateOrderData, resetOnlineOrder }}>
            {children}
        </OnlineOrderContext.Provider>
    );
};

export default OnlineOrderProvider;
import React, { createContext, useEffect, useState, useContext } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { VipCardContext } from './VipCardContext';

// Create a Context for the cart
export const CartContext = createContext();

// Create a provider component
const CartProvider = ({ children }) => {
    const { selectedVipCard } = useContext(VipCardContext);
    const [cartItems, setCartItems] = useState([]);
    const [selectedVipCardDiscount, setSelectedVipCardDiscount] = useState(undefined);
    const [discountsForWholeCart, setDiscountsForWholeCart] = useState({ amount: 0, percentage: selectedVipCardDiscount || selectedVipCard?.discountAmount ||0 });
    const [serviceFeeAmount, setServiceFeeAmount] = useState(0);
    const [alreadySentCartItems, setAlreadySentCartItems] = useState([]);
    const [originalCartTotal, setOriginalCartTotal] = useState(cartItems.reduce((total, item) => total + (item.price * (item.quantity || 1)), 0));
    const [paymentModalVisible, setPaymentModalVisible] = useState(false);

    useEffect(() => {
        setOriginalCartTotal(cartItems.reduce((total, item) => total + (item.price * (item.quantity || 1)), 0) + alreadySentCartItems?.length > 0 ? alreadySentCartItems.reduce((total, item) => total + (item.remotePrice ? item.remotePrice : (item.price * (item.amount || 1))), 0) : 0)
    }, [cartItems]);

    useEffect(() => {
        if (selectedVipCard) {
            setSelectedVipCardDiscount(selectedVipCard.discountAmount)
            setDiscountsForWholeCart(prev => ({ ...prev, percentage: selectedVipCard.discountAmount }));
        }else{
            setDiscountsForWholeCart(prev => ({ ...prev, percentage: 0 }));
        }
    }, [selectedVipCard]);

    const alreadySentCartItemsPrice = alreadySentCartItems?.reduce((total, item) => total + (item.remotePrice ? item.remotePrice : (item.price * (item.amount || 1))), 0);
    const productsPrice = (cartItems.reduce((total, item) => total + (item.remotePrice ? item.remotePrice : (item.price * (item.quantity || 1))), 0) + alreadySentCartItemsPrice);
    //For now if there is item discount and whole cart discount, the whole cart discount will be calculated from, the already reduced item prices sum
    const totalDiscountPercentage =  productsPrice * (Number(discountsForWholeCart?.percentage)/100);
    const cartTotal = Number(productsPrice) - Number(discountsForWholeCart?.amount) - Number(totalDiscountPercentage);
    const cartTotalWithServiceFee = cartTotal + serviceFeeAmount;

    useEffect(() => {
        const fetchServiceFee = async () => {
            const restaurantPlace = await AsyncStorage.getItem('restaurantPlace');
            const serviceFee = JSON.parse(restaurantPlace).serviceFee;
            const showServiceFee = JSON.parse(restaurantPlace).showServiceFee;

            if (serviceFee && showServiceFee === true) {
                const serviceFeeValue = Number(serviceFee);
                //serviceFee calculation from the sum of product's prices
                setServiceFeeAmount(Number(((serviceFeeValue / 100) * productsPrice).toFixed(1)));
            } else {
                setServiceFeeAmount(0);
            }
        };

        fetchServiceFee();
    }, [cartTotal, productsPrice]);

    const addToCart = (item) => {
        setCartItems([...cartItems, item]);
    };

    const setCart = (newCartItems) => {
        setCartItems([...newCartItems]);
    };

    const addComment = (itemId, comment) => {
        if (!comment || comment === "") return false;
        const newCartItems = cartItems.map(item => {
            if (item._id === itemId) {
                if (!item.comments) {
                    item.comments = [comment];
                } else {
                    item.comments.push(comment);
                }
            }
            return item;
        });
        setCartItems(newCartItems);
        return true;
    };

    const addDiscount = (itemId, discount, openFrom) => {
        const discountAmount = Number(discount.amount);
        const discountPercentage = Number(discount.percentage);
        if (openFrom === "cartItem") {
            const newCartItems = cartItems.map(item => {
                if (item._id === itemId) {
                    item.discounts = { amount: discountAmount, percentage: discountPercentage };
                    const fullPrice = item.price * item.quantity;
                    const discountFromPercentage = fullPrice * (item.discounts.percentage / 100);
                    item.remotePrice = fullPrice - item.discounts.amount - discountFromPercentage;
                }
                return item;
            });
            setCartItems(newCartItems);
        } else if (openFrom === "cartTotal") {
                setDiscountsForWholeCart({ amount: discountAmount, percentage: discountPercentage });
        }
    };

    const removeFromCart = (itemId) => {
        setCartItems(cartItems.filter(item => item._id !== itemId));
    };

    const deleteCart = () => {
        setCartItems([]);
        if(selectedVipCard){
            setDiscountsForWholeCart({ amount: 0, percentage: selectedVipCardDiscount || selectedVipCard?.discountAmount || 0});
        }else{
            setDiscountsForWholeCart({ amount: 0, percentage: 0 });
        }

    };

    return (
        <CartContext.Provider value={{ cartItems, addToCart, removeFromCart, setCart, cartTotal, deleteCart, addComment, addDiscount, originalCartTotal, productsPrice, discountsForWholeCart, setDiscountsForWholeCart, setCartItems, serviceFeeAmount, cartTotalWithServiceFee, alreadySentCartItems, setAlreadySentCartItems, paymentModalVisible, setPaymentModalVisible }}>
            {children}
        </CartContext.Provider>
    );
};
export default React.memo(CartProvider);
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Dimensions, Platform, ToastAndroid, Alert} from 'react-native';
import {generateObjectId} from "./objectId";
import {generateHappyId} from "./happyId";
import {getAccessToken, getUserInfo} from "./storage";
import VivaPaymentModule from "../paymentModules/VivaPaymentsModule";
import RawBTPrintTicket from "./rawBTDriver";

const API_URL = 'https://api.happygastro.hu/api';


const {width, height} = Dimensions.get('window');

export const send_storeOrder = async (cartTotal, cartItems, paymentType, paidAmount, discountsForWholeCart, vatInvoiceData, needInvoice, currentTable, serviceFeeAmount, isSavedForLater = false, alreadySentCartItems, orderId, paymentTypes, tip, originalAmount, selectedVipCard, userData) => {
    const tipAmount = tip || 0;
    console.log("tipAmount:", tipAmount)
    console.log("userdata in send", userData)
    const convertPaymentType = () => {
        if (paymentType === "Készpénz") return "cash";
        if (paymentType === "Bankkártya") return "creditCard";
        if (paymentType === "Utalvány") return "voucher";
        if(paymentType === "VIP") return "vipBalance";
        return "temporary";
    }
    const restaurantData = await AsyncStorage.getItem("restaurant");
    const restaurantPlaceData = await AsyncStorage.getItem("restaurantPlace");
    //const userData = await getUserInfo();

    //const orderId = generateObjectId();
    const orderCode = generateHappyId();

    const restaurant = JSON.parse(restaurantData);
    const restaurantPlace = JSON.parse(restaurantPlaceData);

    const itemsArrayforOrder = [
        ...(cartItems ?? []).map(item => {
            return {
                "item": item.itemId,
                "sizeIndex": item.sizeIndex || 0,
                "size": item.sizes[item?.sizeIndex || 0]._id,
                "quantity": (item.amount || item.quantity || 1),
                "extras": item.extras || [],
                "serveType": item.serveType || "",
                "comments": item.comments || [],
                "discounts": item.discounts || {},
                "round": item.round || 0,
                "amount": (item.amount || item.quantity || 1),
                "price": item.price,
                "remotePrice": item.remotePrice || item.price,
            };
        }),
        ...(alreadySentCartItems ?? []).map(item => {
            return {
                "item": item.item,
                "sizeIndex": item.sizeIndex,
                "size": item.size,
                "quantity": (item.amount || item.quantity || 1),
                "extras": item.extras || [],
                "serveType": item.serveType || "",
                "comments": item.comments || [],
                "discounts": item.discounts || {},
                "round": item.round || 0,
                "amount": (item.amount || item.quantity || 1),
                "price": item.price,
                "remotePrice": item.remotePrice || item.price,
            };
        })
    ];
    const invoiceData = {
        "company": {
            "addressData": {
                "fullAddress" : vatInvoiceData?.fullAddress || "",
                "city" : vatInvoiceData?.city || "",
                "postCode" : vatInvoiceData?.postCode || "",
                "street" : vatInvoiceData?.address || "",
                "streetType" : vatInvoiceData?.streetType || "",
                "houseNumber" : vatInvoiceData?.houseNumber || "",
            },
            "nev": vatInvoiceData?.name || "",
            "adoszam": vatInvoiceData?.taxRefNum || "",
            "cim": vatInvoiceData?.fullAddress || "",
            "cegj_sz": vatInvoiceData?.cegj_sz || "",
            "arbev": vatInvoiceData?.arbev || "",
        },
        "firstName": vatInvoiceData?.firstName || "",
        "lastName": vatInvoiceData?.lastName || "",
        "email": vatInvoiceData?.email || "",
        "phone": vatInvoiceData?.phone || "",
        "comment": vatInvoiceData?.comment || "",
    }
    const currentPaymentType = convertPaymentType();
    const bodyPaymentTypes = paymentTypes && paymentTypes.length > 0 ? paymentTypes : [
        {
            "paymentType": convertPaymentType(),
            "amount": paidAmount || cartTotal || 0,
            "change": 1,
            "currency": "HUF",
        }
    ]
    const bodyData = {
        "VIPCard": userData ? null : (selectedVipCard? selectedVipCard : null),
        "generatedId": orderId || "",
        "orderCode": orderCode,
        "restaurant": restaurant._id,
        "addressInfo": userData?.addressInfo || {},
        "userInfo": userData?.userInfo || {},
        "placeId": restaurantPlace._id,
        "preOrder": userData?.preOrder ? userData?.preOrder : null,
        "orderType": (userData?.userInfo && userData?.addressInfo) ? (userData?.orderType ? userData.orderType : "online") : isSavedForLater? "dineIn" : "takeAway",
        "serviceFee":  currentTable?._id && restaurantPlace?.serviceFee || 0,
        "deliveryFee": 0,
        "serviceFeePrice": currentTable?._id && serviceFeeAmount || 0,
        "serviceFeeName": "",
        "orderFrom": restaurantPlace._id,
        "selectedPortal": restaurantPlace._id,
        "proforma": false,
        "tableId": currentTable?._id || "",
        "tableName": currentTable?.name || "",
        "tipAmount": tipAmount,
        "comment": "",
        "discounts": discountsForWholeCart,
        "paymentType": paymentTypes && paymentTypes.length > 0 ? "cash" : currentPaymentType, //todo switch cash to paymentType option for splitorders
        "paymentTypes": isSavedForLater? [] : bodyPaymentTypes,
        "items": [
            ...itemsArrayforOrder
        ],
        "cartItems": [],
        "needInvoice": needInvoice || false,
    };
    // console.log("bodydata", bodyData)

    if(isSavedForLater){
        bodyData["isPayed"] = false;
    }
    // console.log(bodyData)
    const token = await getAccessToken(); // Retrieve the token from AsyncStorage
    if(vatInvoiceData){return fetch(API_URL + '/order/create', {
        method: 'POST',
        headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
        body: JSON.stringify({...bodyData,  invoiceData : invoiceData}),
    })
        .then(response => response.json());
    }else{

        return fetch(API_URL + '/order/create', {
            method: 'POST',
            headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token},
            body: JSON.stringify({...bodyData}),
        })
            .then(response => response.json());
    }


}

export const handleVivaPayment = (paidAmount) => {
    const paymentAmount = Number(paidAmount) * 100; // Amount in minor units (e.g., cents)
    return VivaPaymentModule.initiatePayment(paymentAmount);
};


export const handlePayment = async (paymentType, paidAmount, originalAmount, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, successPayment = false, currentTable, serviceFeeAmount, alreadySentCartItems, orderId, paymentTypes, tip, selectedVipCard) => {
    if (!paymentType && (!paymentTypes || paymentTypes && paymentTypes.length === 0)) {
        if (Platform.OS === "android") {
            return ToastAndroid.showWithGravity('Kérlek válassz fizetési módot', 5000, ToastAndroid.CENTER);
        } else {
            setProcessing(false);
            return alert('Kérlek válassz fizetési módot');
        }
    }
    if(paymentTypes && paymentTypes.length > 0){
       const paymentsSum = paymentTypes.reduce((acc, curr) => { return acc + curr.amount }, 0)
         if (Math.round(paymentsSum) + 4 < Math.round(originalAmount) || (paymentType !== "Készpénz" && paymentsSum +4 < Math.round(originalAmount))) {
              if (Platform.OS === "android") {
                return ToastAndroid.showWithGravity('A fizetett összeg nem lehet kevesebb az eredeti összegnél', 5000, ToastAndroid.CENTER);
              } else {
                setProcessing(false);
                return alert('A fizetett összeg nem lehet kevesebb az eredeti összegnél');
              }
         }
    }else if (paidAmount + 4 < originalAmount || (paymentType !== "Készpénz" && paidAmount +4 < Math.round(originalAmount))) {
        if (Platform.OS === "android") {
            return ToastAndroid.showWithGravity('A fizetett összeg nem lehet kevesebb az eredeti összegnél', 5000, ToastAndroid.CENTER);
        } else {
            setProcessing(false);
            return alert('A fizetett összeg nem lehet kevesebb az eredeti összegnél');
        }
    }
    if (paidAmount === "") {
        if (Platform.OS === "android") {
            return ToastAndroid.showWithGravity('Kérlek add meg a fizetett összeget', 5000, ToastAndroid.CENTER);
        } else {
            setProcessing(false);
            return alert('Kérlek add meg a fizetett összeget');
        }
    }
    setProcessing(true);

    const storeOrderResponse = await send_storeOrder(cartTotal, cartItems, paymentType, paidAmount, discountsForWholeCart, vatInvoiceData, needInvoice, currentTable, serviceFeeAmount, false, alreadySentCartItems, orderId, paymentTypes, tip, originalAmount, selectedVipCard);
    //console.log("storeOrderResponse: ", storeOrderResponse);
    try {
        if (!storeOrderResponse) {
            if (Platform.OS === "android") {
                setProcessing(false);
                return ToastAndroid.showWithGravity('Nem sikerült a számla mentése!', ToastAndroid.LONG, ToastAndroid.CENTER, 50);
            } else {
                console.log("Nem sikerült a számla mentése!")
                setProcessing(false);
                return alert('Nem sikerült a számla mentése!');
            }
        }
    } catch (e) {
        setProcessing(false);
        if (Platform.OS === "android") {
            return ToastAndroid.showWithGravity('Nem sikerült a számla mentése!', ToastAndroid.LONG, ToastAndroid.CENTER);
        } else {
            console.log("Nem sikerült a számla mentése!")
            return alert('Nem sikerült a számla mentése!');
        }
    }

    const generateQRCodeString = (storedOrder) => {
        const { orderCode, items, paymentTypes, orderSum, paymentType } = storedOrder;

        // Initialize the QR code string with the base URL and command
        let qrCodeString = `fiscat/AEE|SLD||${orderCode.replace("-", "")}|6|Rendelés száma:|#${orderCode}|-------------|www.happygastro.hu|Üzemelteti a|Happy Gastro||||${items.length}|`;

        // Add each item to the QR code string
        items.forEach(item => {
            const itemName = item.remoteName || item.name?.hu || item.name || 'GYŰJTŐ';
            const vatCode = 2; // Assuming VAT code is always 2
            const itemPrice = item.remotePrice || item.price || '';
            const itemQuantity = item.amount || item.quantity || 1;

            qrCodeString += `${itemName}|${vatCode}|${itemPrice}|${itemQuantity}||`;
        });

        // Add payment types and amounts
        if(storeOrderResponse.paymentTypes && storeOrderResponse.paymentTypes.length > 0) {
            storeOrderResponse.paymentTypes.forEach(payment => {
                const paymentType = payment.paymentType === "cash" ? "P" : "N";
                const paymentAmount = payment.amount || '';

                qrCodeString += `${paymentType}|${paymentAmount}`;
            });
        } else {
            const paymentTypeCode = storeOrderResponse.paymentType === "cash" ? "P" : "N";
            const paymentAmount = orderSum || '';
            qrCodeString += `${paymentTypeCode}|${paymentAmount}`;
        }

        return qrCodeString;
    };

    const sendToCashier = () => {
        try {
            new Promise(async () => {
                const paymentCashier = await AsyncStorage.getItem('cashierDevice');
                if (paymentCashier) {
                    // Send serial Bluetooth data
                    if (Platform.OS === "android") {
                        try {
                            let dataToSend = generateQRCodeString(storeOrderResponse)
                            console.info("Fiscat Data full:", dataToSend);
                            sendData(dataToSend);
                        } catch (e) {
                            Alert.alert("Adóügyi nyomtatási hiba", "Nem sikerült a nyugta nyomtatása a pénztárgépre. Kérjük, próbálja újra!");
                        }
                    }
                }
            });
        } catch (e) {
            setProcessing(false);
            if (Platform.OS === "android") {
                return ToastAndroid.showWithGravity('Nem sikerült a pénztárgép adatküldés!', 15000, ToastAndroid.CENTER);
            } else {
                return alert('Nem sikerült a pénztárgép adatküldés!');
            }
        }
    };

    const printReceiptAndTicket = () => {
        if (Platform.OS !== "web") {
            try {
                // Print receipt tickets
                new Promise(() => RawBTPrintTicket([...cartItems.filter(item => item.ticket), ...alreadySentCartItems?.filter(item => item.ticket)]));

                // Print receipt tickets
                //new Promise(() => RawBTPrintReceipt());
            } catch (e) {
                if (Platform.OS === "android") {
                    return ToastAndroid.showWithGravity('Nem sikerült a nyomtatás!', ToastAndroid.LONG, ToastAndroid.BOTTOM, 50);
                } else {
                    return alert('Nem sikerült a nyomtatás!');
                }
            }
        }
    }

    try {

        if (paymentType === "Bankkártya") {
            return new Promise(async () => {
                const paymentTerminal = await AsyncStorage.getItem('selectedCardTerminal');

                if (paymentTerminal && !successPayment) return await handleVivaPayment(paidAmount, sendToCashier, printReceiptAndTicket); // Implement this function as needed
                if(!paymentTerminal || successPayment) {
                    sendToCashier();
                    printReceiptAndTicket();
                    setCart([]);
                    if (width >= 768) {
                        console.log("payment success1")
                        onClose();
                        return navigation.navigate("HomeScreen", {paymentSuccess: true, orderResponse: storeOrderResponse});
                    } else {
                        console.log("payment success2")
                        return navigation.navigate("SuccessPayment", {orderResponse: storeOrderResponse});
                    }
                }
            });
        }

        if (paymentType === "Készpénz") {
            sendToCashier();
            printReceiptAndTicket();
            setCart([]);
            if (width >= 768) {
                console.log("payment success13")
                onClose();
                return navigation.navigate("HomeScreen", {paymentSuccess: true, orderResponse: storeOrderResponse});
            } else {
                console.log("payment success22")
                return navigation.navigate("SuccessPayment", {orderResponse: storeOrderResponse});
            }
        }
        if (paymentType === "Utalvány") {
            sendToCashier();
            printReceiptAndTicket();
            setCart([]);
            if (width >= 768) {
                console.log("payment success13")
                onClose();
                return navigation.navigate("HomeScreen", {paymentSuccess: true, orderResponse: storeOrderResponse});
            } else {
                console.log("payment success23")
                return navigation.navigate("SuccessPayment", {orderResponse: storeOrderResponse});
            }
        }
        if (!paymentType && paymentTypes && paymentTypes.length > 0) {
            sendToCashier();
            printReceiptAndTicket();
            setCart([]);
            if (width >= 768) {
                console.log("payment success13")
                onClose();
                return navigation.navigate("HomeScreen", {paymentSuccess: true, orderResponse: storeOrderResponse});
            } else {
                console.log("payment success22")
                return navigation.navigate("SuccessPayment", {orderResponse: storeOrderResponse});
            }
        }

    } catch (error) {
        setProcessing(false);
        console.log(error)
        if (width >= 768) {
            onClose();
            return navigation.navigate("HomeScreen", {paymentSuccess: false, orderResponse: JSON.stringify(error)});
        } else {
            navigation.navigate("FailedPayment", {orderResponse: error});
        }
    } finally {
        setProcessing(false);
    }
};
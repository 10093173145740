import React, {useState} from "react";

import {Provider as ThemeProvider} from "react-native-paper";
import {StatusBar, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {NavigationContainer} from "@react-navigation/native";
import {createStackNavigator} from "@react-navigation/stack";
import {theme} from "./app/core/theme";
import {
    HomeScreen,
    LoginScreen,
    NotificationsScreen, OnlineScreen,
    OrderDetailsScreen, SettingsPrinterPairingScreen,
    StartScreen,
    SuccessEntryScreen,
    SuccessPaymentScreen,
    TablesScreen,
    TermsOfServicesScreen,

} from "./app/screens";
import VipUserScreen from "./app/screens/VipUserScreen";
import VipUserDetailScreen from './app/screens/VipUserDetailScreen';
import Toast from 'react-native-toast-message';
import SplashScreenComponent from './app/screens/SplashScreen';
import {Ionicons} from '@expo/vector-icons';
import PaymentScreen from "./app/screens/PaymentScreen";
import ResultScreen from "./app/screens/ResultScreen";
import WaitingForPayment from "./app/screens/WaitingForPayment";
import FailedPaymentScreen from "./app/screens/FailedPaymentScreen";
import TicketEntryScreen from "./app/screens/TicketEntryScreen";
import {BluetoothProvider} from "./app/helpers/BluetoothContext";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {Root} from "react-native-popup-confirm-toast";

import FailedEntryScreen from "./app/screens/FailedEntryScreen";
import PaymentsSettingsScreen from "./app/screens/PaymentsSettingsScreen";
import ScanDevicesScreen from "./app/screens/ScanDevicesScreen";
import PrivacyPolicyScreen from "./app/screens/PrivacyPolicyScreen";
import {activateKeepAwakeAsync} from 'expo-keep-awake';

import * as TaskManager from 'expo-task-manager';
import * as BackgroundFetch from 'expo-background-fetch';
import DayClosingScreen from "./app/screens/DayClosingScreen";
import DayOpeningScreen from "./app/screens/DayOpeningScreen";
import DayOpeningConfirmationScreen from "./app/screens/DayOpeningConfirmationScreen";
import Observables from '@happy-gastro/global-observables';
import DesignSettingsScreen from "./app/screens/DesignSettingsScreen";
import SuccessDayClosingScreen from "./app/screens/SuccessDayClosingScreen";
import SupportChatScreen from "./app/screens/SupportChatScreen";

import {persistor, store} from "./app/store";
import {Provider as ReduxProvider} from "react-redux";
import {PersistGate} from "redux-persist/integration/react";

import './happyWebStyles.css';
import {TableProvider} from "./app/helpers/TableContext";
import SettingsPrinterSearchScreen from "./app/screens/SettingsPrinterSearchScreen";
import StatisticsScreen from "./app/screens/StatisticsScreen";
import * as Font from "expo-font";
import SystemProvider from "./app/helpers/SystemContext";
import CartProvider from "./app/helpers/CartContext";
import OrdersProvider from "./app/helpers/OrdersContext";
import OrderListScreen from "./app/screens/OrderListScreen";
import AddVipUserScreen from "./app/screens/AddVipUserScreen";
import { VipCardProvider } from './app/helpers/VipCardContext';
import {priceFormatter} from "./app/helpers/numbers";
import OrderAcceptTimePicker from "./app/components/OrderAcceptTimePicker";
import OrderRejectModal from "./app/components/OrderRejectModal";
import OrderDetailsModal from "./app/helpers/OrderDetailsModal";
import axios from "axios";
import {ApiConfig} from "./app/config/ApiConfig";
import {getAccessToken} from "./app/helpers/storage";
import OnlineOrderProvider from './app/helpers/OnlineOrderContext';
import SettingsScreen from "./app/screens/SettingsScreen";
import OrdersScreen from "./app/screens/OrdersScreen";
import TablesBookingsScreen from "./app/screens/TablesBookingsScreen";
import NewBookingModal from "./app/helpers/NewBookingModal";
import BookingResultModal from "./app/helpers/BookingResultModal";


let moment = require('moment');
require('moment/locale/hu');
moment.locale('hu');


const BACKGROUND_FETCH_TASK = 'happy-services-sync';
TaskManager.defineTask(BACKGROUND_FETCH_TASK, async (result) => {
    try {
        // Perform your background task here
        // Example: Fetch data from an API
        console.log('Background fetch task running');
        // const orders = await fetchOrdersFromAPI();

        return {type: 'BACKGROUND_TASK', action: BACKGROUND_FETCH_TASK, result: [...result]};
    } catch (error) {
        console.error('Background fetch task error:', error);
        return false;
    }
});


const Stack = createStackNavigator();

export default function App({navigation, route}) {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [appIsReady, setAppIsReady] = useState(false);
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const [isDayOpen, setIsDayOpen] = useState(false);

    const removeToast = (id) => {
        Toast.hide(id)
    }

    const toastConfig = {
        pendingOrderToast: ({ props }) => {
            const [isAcceptModalVisible, setAcceptModalVisible] = useState(false);
            const [isRejectModalVisible, setIsRejectModal] = useState(false);
            const [isDescriptionOpened, setIsDescriptionOpened] = useState(false);
            const handleAccept = async (time) => {
                const token = await getAccessToken()
                new Promise((resolve, reject) => axios.post(ApiConfig.apiURL + updateURL, {
                    "status": "preparing",
                    "acceptTime": time,
                    "headers": {
                        "Accept-Language": "hu_HU",
                        "authorization": `Bearer ${token}`,
                    },
                }))
                setAcceptModalVisible(false)
            }
            const reverseConvertPaymentType = (paymentType) => {
                if (paymentType === "cash") return "Készpénz";
                if (paymentType === "creditCard") return "Bankkártya";
                if (paymentType === "voucher") return "Utalvány";
                if (paymentType === "vipBalance") return "VIP egyenleg";
                return `${paymentType}`;
            }
            const handleReject = async (reason) => {
                const token = await getAccessToken()
                new Promise((resolve, reject) => axios.post(ApiConfig.apiURL + updateURL, {
                    "status": "cancelled",
                    "rejectReason": reason,
                    "storno": true,
                    "headers": {
                        "Accept-Language": "hu_HU",
                        "authorization": `Bearer ${token}`,
                    },
                }))
                setIsRejectModal(false)
            }
            const updateURL = "/api/order/"+props._id+"/update"
            return (<>
                    <OrderAcceptTimePicker
                        isVisible={isAcceptModalVisible}
                        onClose={() => setAcceptModalVisible(false)}
                        onConfirm={handleAccept}
                    />
                    <OrderRejectModal
                        isVisible={isRejectModalVisible}
                        onClose={() => setIsRejectModal(false)}
                        onConfirm={handleReject}
                    />
                    <OrderDetailsModal
                        orderData={props}
                        isVisible={isDescriptionOpened}
                        onClose={() => setIsDescriptionOpened(false)}
                        onConfirm={() => {
                            setIsDescriptionOpened(false)
                        }}
                    />

                    <View style={styles.container}>
                        <Text style={styles.title}>{props.message || "Új beérkező rendelés"}</Text>

                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Text
                                style={styles.subText}>{props.orderType === "online" ? "Házhozszálltás" : "Elviteles"}</Text>
                            <Text style={styles.subText}>Végösszeg: {priceFormatter(props.orderSum || 0)}</Text>
                        </View>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Text style={styles.subText}>Fizetési mód: </Text>
                        <Text style={[styles.subText, {fontWeight: "bold"}]}>{reverseConvertPaymentType(props.paymentType || props.paymentTypes[0])}</Text>
                    </View>
                        <View style={styles.buttonContainer}>
                            <TouchableOpacity style={[styles.button, styles.rejectButton]}
                                              onPress={() => setIsRejectModal(true)}>
                                <Text style={styles.buttonText}>Elutasít</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.button, styles.openButton]}
                                              onPress={() => setIsDescriptionOpened(true)}>
                                <Text style={styles.buttonText}>Megtekintés</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={[styles.button, styles.acceptButton]}
                                              onPress={() => setAcceptModalVisible(true)}>
                                <Text style={styles.buttonText}>Elfogad</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </>
            );
        }
    };

    React.useEffect(() => {

        const keepAwake = async () => {
            await activateKeepAwakeAsync();
        }
        keepAwake();

        Observables.register('reloadDayOpeningClosing', async (data) => {
            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        });

        const loadFont = async () => {

            await Font.loadAsync({
                "MaterialIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialIcons.ttf"),
                "FontAwesome": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome.ttf"),
                "FontAwesome5": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/FontAwesome5_Regular.ttf"),
                "Ionicons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Ionicons.ttf"),
                "MaterialCommunityIcons": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf"),
                "Feather": require("@expo/vector-icons/build/vendor/react-native-vector-icons/Fonts/Feather.ttf")
            });

            const dayOpened = await AsyncStorage.getItem('dayOpened');
            if (dayOpened === 'true' || dayOpened) {
                setIsDayOpen(true);
            } else {
                setIsDayOpen(false);
            }
        };

        loadFont();
    }, []);


    React.useEffect(() => {
        const initBackgroundFetch = async () => {
            try {
                await BackgroundFetch.registerTaskAsync(BACKGROUND_FETCH_TASK, {
                    minimumInterval: 15, // Fetch interval in minutes
                    stopOnTerminate: false, // Continue running after app is terminated
                    startOnBoot: true, // Start task when device is booted
                });
                console.log('Background fetch task registered');
            } catch (error) {
                console.error('Background fetch task registration error:', error);
            }
        };

        initBackgroundFetch();
    }, []);

    const handleAnimationFinish = () => {
        setIsSplashVisible(false);
    };

    React.useEffect(() => {
        if (route?.params?.successCloseDay) {
            setIsDayOpen(route?.params?.successCloseDay || false);
        }
    }, [route]);

    if (isSplashVisible) {
        return <SplashScreenComponent onAnimationFinish={handleAnimationFinish}/>;
    }

    // TODO: Change back to StartScreen instead of HomeScreen, and make isLoggedIn: false
    return (
        <ThemeProvider theme={theme}>
            <NavigationContainer>
                <Root>
                    <ReduxProvider store={store}>
                        <PersistGate loading={null} persistor={persistor}>
                            <VipCardProvider>
                            <OrdersProvider>
                                <OnlineOrderProvider>
                                <SystemProvider>
                                    <TableProvider>
                                    <CartProvider>
                                        <BluetoothProvider>
                                            <StatusBar hidden={true} backgroundColor="transparent"
                                                       barStyle="dark-content"/>
                                            <Stack.Navigator
                                                initialRouteName={!isLoggedIn ? "StartScreen" : (isDayOpen ? "HomeScreen" : "DayOpeningScreen")}>
                                                <Stack.Screen
                                                    name="StartScreen"
                                                    component={StartScreen}
                                                    options={{
                                                        title: 'Kezdőképernyő',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="LoginScreen"
                                                    component={LoginScreen}
                                                    options={{
                                                        title: 'Bejelentkezés',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="HomeScreen"
                                                    component={HomeScreen}
                                                    options={{
                                                        title: 'Értékesítés',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TablesScreen"
                                                    component={TablesScreen}
                                                    options={{
                                                        title: 'Asztaltérkép',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="OnlineScreen"
                                                    component={OnlineScreen}
                                                    options={{
                                                        title: 'Házhozszállítás',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TermsOfServicesScreen"
                                                    component={TermsOfServicesScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Felhasználási feltételek",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayOpeningScreen"
                                                    component={DayOpeningScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap nyitása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayOpeningConfirmationScreen"
                                                    component={DayOpeningConfirmationScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap nyitása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DayClosingScreen"
                                                    component={DayClosingScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Pénzügyi nap zárása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />

                                                <Stack.Screen
                                                    name="StatisticsScreen"
                                                    component={StatisticsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Napi kimutatás",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />

                                                <Stack.Screen name="OrderListScreen" component={OrderListScreen}
                                                              options={{
                                                                  headerShown: true,
                                                                  title: "Mai rendelések",
                                                                  headerTitleAlign: 'center', // Center align the title text
                                                                  backTitle: "Vissza",
                                                                  gestureEnabled: true,
                                                                  transitionSpec: {
                                                                      open: {animation: 'timing', config: {duration: 500}},
                                                                      close: {animation: 'timing', config: {duration: 500}},
                                                                  },
                                                              }}/>
                                                <Stack.Screen
                                                name="SuccessDayClosingScreen"
                                                component={SuccessDayClosingScreen}
                                                options={{
                                                    headerShown: false,
                                                    title: "Sikeres pénzügyi nap zárás",
                                                    headerTitleAlign: 'center', // Center align the title text
                                                    backTitle: "Vissza",
                                                    gestureEnabled: true,
                                                    transitionSpec: {
                                                        open: {animation: 'timing', config: {duration: 500}},
                                                        close: {animation: 'timing', config: {duration: 500}},
                                                    },
                                                }}
                                            />
                                                <Stack.Screen
                                                    name="PrivacyPolicyScreen"
                                                    component={PrivacyPolicyScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Adatvédelmi nyilatkozat",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="PaymentsSettingsScreen"
                                                    component={PaymentsSettingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Fizetési és adóügyi eszközök",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SettingsScreen"
                                                    component={SettingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Beállítások",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="DesignSettingsScreen"
                                                    component={DesignSettingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Megjelenés beállításai",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SettingsPrinterSearchScreen"
                                                    component={SettingsPrinterSearchScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Nyomtatók keresése a hálózaton",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SettingsPrinterPairingScreen"
                                                    component={SettingsPrinterPairingScreen}
                                                    options={({ navigation }) => ({
                                                        headerShown: true,
                                                        title: "Nyomtató profilok",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: { animation: 'timing', config: { duration: 500 } },
                                                            close: { animation: 'timing', config: { duration: 500 } },
                                                        },
                                                        headerRight: () => (
                                                            <TouchableOpacity style={{ marginRight: 15, flexDirection: "row" }} onPress={() => navigation.navigate('SettingsPrinterSearchScreen')}>
                                                                <Ionicons name="print-outline" size={24} color="black" />
                                                                <Ionicons name="search-outline" size={15} color="black" />
                                                            </TouchableOpacity>
                                                        ),
                                                    })}
                                                />
                                                <Stack.Screen
                                                    name="SupportChatScreen"
                                                    component={SupportChatScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Support támogatás",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="OrderDetailsScreen"
                                                    component={OrderDetailsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Rendelés részletei",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="ScanDevicesScreen"
                                                    component={ScanDevicesScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Vezeték nélküli eszközök",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="NotificationsScreen"
                                                    component={NotificationsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Értesítések",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="VipUserScreen"
                                                    component={VipUserScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "VIP Felhasználók",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: { animation: 'timing', config: { duration: 500 } },
                                                            close: { animation: 'timing', config: { duration: 500 } },
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="AddVipUserScreen"
                                                    component={AddVipUserScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "VIP Felhasználók Hozzáadása",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: { animation: 'timing', config: { duration: 500 } },
                                                            close: { animation: 'timing', config: { duration: 500 } },
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="VipUserDetailScreen"
                                                    component={VipUserDetailScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Felhasználó részletei",
                                                        headerTitleAlign: 'center',
                                                        backTitle: "Back",
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: { animation: 'timing', config: { duration: 500 } },
                                                            close: { animation: 'timing', config: { duration: 500 } },
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="PaymentScreen"
                                                    component={PaymentScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Fizetés",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SuccessPayment"
                                                    component={SuccessPaymentScreen}
                                                    options={{
                                                        title: 'Sikeres fizetés!',
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },

                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="FailedPayment"
                                                    component={FailedPaymentScreen}
                                                    options={{
                                                        headerShown: false,
                                                        gestureEnabled: true,
                                                        title: 'Sikertelen fizetés!',
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 500}},
                                                            close: {animation: 'timing', config: {duration: 500}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TicketEntry"
                                                    component={TicketEntryScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Beléptetés",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="SuccessEntryScreen"
                                                    component={SuccessEntryScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Sikeres beléptetés!",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="FailedEntryScreen"
                                                    component={FailedEntryScreen}
                                                    options={{
                                                        headerShown: false,
                                                        title: "Sikertelen beléptetés!",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="ResultScreen"
                                                    component={ResultScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Fizetési eredménye",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="PendingOrdersScreen"
                                                    component={OrdersScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Előrendelések",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="TablesBookingsScreen"
                                                    component={TablesBookingsScreen}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Asztalfoglalások",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerRight: () => {
                                                            const [modalVisible, setModalVisible] = React.useState(false)
                                                            const [hasBookingResult, setHasBookingResult] = React.useState(false)
                                                            const handleBookingSubmit = (data) => {
                                                                console.log("Data received:", data);
                                                                setHasBookingResult(data)
                                                            }
                                                            return (<React.Fragment>
                                                                <TouchableOpacity style={{marginRight: 15}}
                                                                                  onPress={() => setModalVisible(true)}>
                                                                    <Ionicons name="add-circle-outline" size={35}
                                                                              color="white"/>
                                                                </TouchableOpacity>
                                                                <NewBookingModal
                                                                    visible={modalVisible}
                                                                    onClose={() => setModalVisible(false)}
                                                                    onSubmit={handleBookingSubmit}
                                                                />
                                                                <BookingResultModal
                                                                    visible={!!hasBookingResult}
                                                                    bookingResult={hasBookingResult}
                                                                    onClose={setHasBookingResult}
                                                                />
                                                            </React.Fragment>);
                                                        }
                                                    }}
                                                />
                                                <Stack.Screen
                                                    name="WaitingForPayment"
                                                    component={WaitingForPayment}
                                                    options={{
                                                        headerShown: true,
                                                        title: "Várakozás a fizetésre...",
                                                        headerTitleAlign: 'center', // Center align the title text
                                                        backTitle: "Vissza",
                                                        leftIconColor: '#ffffff',
                                                        gestureEnabled: true,
                                                        headerStyle: {
                                                            backgroundColor: '#393939', // Set the desired background color
                                                        },
                                                        headerTintColor: '#ffffff', // Change the back arrow color here
                                                        headerTitleStyle: {
                                                            color: 'rgba(255,255,255,1)', // Set the desired color
                                                        },
                                                        icon: "#FEFEFE",
                                                        transitionSpec: {
                                                            open: {animation: 'timing', config: {duration: 100}},
                                                            close: {animation: 'timing', config: {duration: 100}},
                                                        },
                                                        headerLeft: null, // Disable left icon
                                                        headerRight: null, // Disable right icon
                                                    }}
                                                />
                                            </Stack.Navigator>
                                        </BluetoothProvider>
                                    </CartProvider>
                                    </TableProvider>
                                </SystemProvider>
                                </OnlineOrderProvider>
                            </OrdersProvider>
                            </VipCardProvider>
                        </PersistGate>
                    </ReduxProvider>
                </Root>
            </NavigationContainer>



            <Toast config={toastConfig}/>
        </ThemeProvider>
    );
}

const styles = StyleSheet.create({
    modal: {
        justifyContent: 'flex-end',
        alignContent: 'flex-start',
        margin: 10,
        flexGrow: 1,
        zIndex: 1
    },
    container: {
        backgroundColor: 'white',
        width: 400,
        padding: 20,
        borderRadius: 10,
        marginBottom: 10, // Add margin to create space between toasts

        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: -1,
        },
        shadowOpacity: 0.29,
        shadowRadius: 4.65,

        elevation: 7,
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center'
    },
    subText: {
        fontSize: 14,
        marginVertical: 10,
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 20,
    },
    button: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 5,
    },
    rejectButton: {
        backgroundColor: 'red',
    },
    acceptButton: {
        backgroundColor: 'green',
    },
    openButton: {
        backgroundColor: 'orange',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },

    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

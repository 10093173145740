import React, {useState, useEffect} from 'react';
import {View, Text, FlatList, TouchableOpacity, StyleSheet, ScrollView } from 'react-native';
import { priceFormatter } from '../helpers/numbers';
import { Ionicons } from '@expo/vector-icons';
import {SegmentedButtons} from "react-native-paper";
import {LinearGradient} from "expo-linear-gradient";

const OrderListScreen = ({ route, navigation }) => {
    const { orders } = route.params;
    const [filteredOrders, setFilteredOrders] = useState(orders);
    const [selectedFilter, setSelectedFilter] = useState('Összes');

    // async function fetchOrdersFromAPI (startDate, endDate) {
    //     try {
    //         const token = await getAccessToken(); // Retrieve the token from AsyncStorage
    //
    //         const restaurantData = await AsyncStorage.getItem("restaurant");
    //         if (!token || !restaurantData) return;
    //         const URL_ENDING = '/restaurant/' + JSON.parse(restaurantData)._id + `/order?all=true&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}&lowKey=false&beta=false&mobile=false&newType=false`;
    //
    //         const response = await fetch('https://api.happygastro.hu/api' + URL_ENDING, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`, // Add the token to the Authorization header
    //                 'Content-Type': 'application/json'
    //             }
    //         });
    //         const data = await response.json();
    //         console.log("fetch",data);
    //         setOrders(data);
    //     } catch (error) {
    //         if (Platform.OS === "android") alert(error.message);
    //         if (Platform.OS === "web") alert(error.message);
    //         console.error("Failed to fetch orders from API:", error);
    //         //loadOrdersFromStorage(); // Load from storage if the API fails
    //     }
    // }
    // useEffect(() => {
    //     fetchOrdersFromAPI(startDate, endDate);
    // }, [startDate, endDate]);

    useEffect(() => {
        applyFilter(selectedFilter);
        console.log(orders)
    }, [orders, selectedFilter]);


    const applyFilter = (filter) => {
        setSelectedFilter(filter);
        switch (filter) {
            case 'Kedvezményes':
                setFilteredOrders(orders.filter(order => order?.discount?.amount > 0 || order?.discount?.percentage > 0 || order?.discountPrice > 0 || order?.discountAmount > 0));
                break;
            case 'Sztornó':
                setFilteredOrders(orders.filter(order => (order?.storno === true || order?.status === "cancelled")));
                break;
            case 'Asztalos':
                setFilteredOrders(orders.filter(order => order.orderType === 'dineIn'));
                break;
            case 'Házhoz':
                setFilteredOrders(orders.filter(order => order.orderType === 'delivery'));
                break;
            case 'Elvitel':
                setFilteredOrders(orders.filter(order => order.orderType === 'takeAway'));
                break;
            default:
                setFilteredOrders(orders);
                break;
        }
    };

    const renderOrder = ({ item }) => {
        const createdAt = new Date(item.createdAt);
        const formattedTime = `${createdAt.getHours()}:${createdAt.getMinutes().toString().padStart(2, '0')}`;

        const paymentType = () => {
            switch (item.paymentType) {
                case 'cash':
                    return 'Készpénz';
                case 'creditCard':
                    return 'Bankkártya';
                case 'voucher':
                    return 'Utalvány';
                default:
                    return 'Készpénz';
            }
        }
        const paymentIcon = () => {
            switch (item.paymentType) {
                case 'cash':
                    return 'cash-outline';
                case 'creditCard':
                    return 'card-outline';
                case 'voucher':
                    return 'pricetag-outline';
                default:
                    return 'cash-outline';
            }
        };

        return (
            <TouchableOpacity style={styles.row} onPress={() => navigation.navigate('OrderDetailsScreen', { orderData: item })}>
                <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}>
                    <Ionicons name="time-outline" size={16} color="black" />
                    <Text style={{ marginLeft: 4 }}>{formattedTime}</Text>
                </View>
                <Text style={{ flex: 1, textAlign: 'center', alignSelf: 'center' }}>#{item.orderCode}</Text>
                <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                        <Ionicons name={paymentIcon()} size={16} color="black" />
                        <Text style={{ marginLeft: 4 }}>{paymentType()}</Text>
                    </View>
                    <Text style={{ textAlign: 'right', fontWeight:"bold" }}>{priceFormatter(item.orderSum)}</Text>
                </View>
            </TouchableOpacity>
        );
    };

    return (
        <LinearGradient
            colors={['#f2e2d2', '#e6d6c1', '#dcd5ce']}
            style={styles.background}
        >
        <View style={styles.container}>
            <View style={styles.header}>
                <ScrollView horizontal nestedScrollEnabled contentContainerStyle={{justifyContent: 'center'}}>

                    <SegmentedButtons
                    density={"small"}
                    style={{ marginTop: 7, flex: 1, width: '100%', height: 40 }}
                    value={selectedFilter}
                    onValueChange={applyFilter}
                    theme={{ colors: { primary: '#fde1b6', onSecondaryContainer: 'white', secondaryContainer: '#F1B44C' }, fonts: { regular: { fontWeight: 'bold' } } }}
                    buttons={[
                        ...['Összes', 'Kedvezményes', 'Sztornó', 'Asztalos', 'Házhoz', 'Elvitel'].map((filter) => ({ checkedColor: 'black', value: filter, label: filter })),
                    ]}
                /></ScrollView>
            </View>
            <View style={styles.ordersContainer}>
                <FlatList
                    data={filteredOrders}
                    renderItem={renderOrder}
                    keyExtractor={(item) => item.orderCode}
                    onEndReachedThreshold={0.5}
                />
            </View>
        </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center'
    },
    background: {
        flex: 1,
    },
    header: {
        padding: 16,
        backgroundColor: '#fff',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    ordersContainer: {
        flex: 1,
        padding: 16,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 16,
        backgroundColor: '#fff',
        borderRadius: 8,
        marginBottom: 8,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '100%',
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        alignItems: 'center',
    },
    filterOption: {
        padding: 10,
        fontSize: 18,
    },
    buttons: {
        backgroundColor: '#f1b34b',
        padding: 5,
        borderRadius: 1,
    },
    pagesContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 16,
    },
});

export default OrderListScreen;
import React, { useContext } from 'react';
import { Alert, Platform } from 'react-native';
import { CartContext } from './CartContext';

const useConfirmNavigation = (navigation) => {
    const { deleteCart, setAlreadySentCartItems} = useContext(CartContext);

    const confirmNavigation = (screen) => {
        if (Platform.OS === 'web') {
            if (window.confirm("Biztosan szeretne ellépni? Ezzel elveszíti a felütött tételeket")) {
                deleteCart();
                setAlreadySentCartItems([]);
                navigation.navigate(screen);
            }
        } else {
            Alert.alert(
                "Biztosan szeretne ellépni?",
                "Ezzel elveszíti a felütött tételeket",
                [
                    {
                        text: "Mégse",
                        style: "cancel"
                    },
                    {
                        text: "Igen",
                        onPress: () => {
                            deleteCart();
                            navigation.navigate(screen);
                        }
                    }
                ],
                { cancelable: false }
            );
        }
    };

    return confirmNavigation;
};

export default useConfirmNavigation;
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Platform } from 'react-native';
import { DatePickerModal, TimePickerModal } from 'react-native-paper-dates';

const DateTimePickerButton = ({ onDateChange }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [showTimePicker, setShowTimePicker] = useState(false);
    const [hours, setHours] = useState(12);
    const [minutes, setMinutes] = useState(0);

    const handleDateConfirm = (params) => {
        setSelectedDate(params.date);
        setShowDatePicker(false);
        setShowTimePicker(true);
    };

    const handleTimeConfirm = ({ hours, minutes }) => {
        const roundedMinutes = Math.round(minutes / 15) * 15 % 60;
        setHours(hours);
        setMinutes(roundedMinutes);
        setShowTimePicker(false);
        const fullDate = new Date(selectedDate);
        fullDate.setHours(hours);
        fullDate.setMinutes(roundedMinutes);
        if (onDateChange) {
            console.log(fullDate.toISOString());
            onDateChange(fullDate.toISOString());
        }
    };

    return (
        <View style={styles.container}>
            <TouchableOpacity
                onPress={() => setShowDatePicker(true)}
                style={styles.button}
            >
                <Text style={styles.buttonText}>
                    {selectedDate
                        ? `Előrendelés: ${selectedDate.toLocaleDateString()} ${hours}:${minutes.toString().padStart(2, '0')}`
                        : 'ELŐRENDELÉS'}
                </Text>
            </TouchableOpacity>

            <DatePickerModal
                label="Válassz egy dátumot"
                locale="hu"
                mode="single"
                saveLabel="Mentés"
                visible={showDatePicker}
                date={selectedDate || new Date()}
                onDismiss={() => setShowDatePicker(false)}
                onConfirm={handleDateConfirm}
            />

            <TimePickerModal
                label="A választott időpont a legközelebbi 15 percre lesz kerekítve"
                visible={showTimePicker}
                confirmLabel="Mentés"
                cancelLabel="Mégse"
                onDismiss={() => setShowTimePicker(false)}
                onConfirm={handleTimeConfirm}
                hours={hours}
                minutes={minutes}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    button: {
        backgroundColor: '#f1b44c',
        padding: 10,
        borderRadius: 5,
        elevation: 3,
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold',
    },
});

export default DateTimePickerButton;

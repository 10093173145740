import React from 'react';
import {View, StyleSheet} from 'react-native';
import Icon from 'react-native-vector-icons/FontAwesome';
import IonIcon from 'react-native-vector-icons/Ionicons';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import SettingsScreen from "./SettingsScreen";
import TableMapScreen from "./TableMapScreen";
import OrdersScreen from "./OrdersScreen";
import TablesBookingsScreen from "./TablesBookingsScreen";
import {OrdersContext} from "../helpers/OrdersContext";
import StatisticsHelper from "../helpers/StatisticsHelper";
import CircularButton from '../components/CircularButton';
import FunctionsScreen from "./FunctionsScreen";

const Tab = createBottomTabNavigator();

export default function TablesScreen({navigation}) {
    const {orders} = React.useContext(OrdersContext);
    const {productsSum, statistics} = StatisticsHelper(orders);

    return (
        <View style={{flex: 1, width: '100%', height: '100%', flexGrow: 1}}>
            <Tab.Navigator
                detachInactiveScreens={false}
                tabBarLabelPosition={"below-icon"}
                lazy={false}
                backBehavior={"history"}
                headerMode={"none"}
                screenOptions={{headerShown: false}}
                tabBarOptions={{
                    activeTintColor: '#a26909',
                    inactiveTintColor: '#575757',
                    activeBackgroundColor: '#f0f0f0',
                    inactiveBackgroundColor: '#ffffff',
                    showLabel: true,
                    labelStyle: {
                        fontSize: 14,
                        fontWeight: 'bold',
                    },
                    tabStyle: {
                        paddingVertical: 10,
                        height: 70,
                        marginTop: -20
                    },
                    style: {
                        height: 70,
                        backgroundColor: '#e0e0e0',
                    }
                }}
            >
                <Tab.Screen name="TableMap" component={TableMapScreen}
                            options={{
                                tabBarIcon: ({color, size}) => (
                                    <Icon name="braille" color={color} size={size}/>
                                ),
                                title: 'Asztaltérkép',
                                tabBarLabel: 'Asztaltérkép',
                            }}/>
                <Tab.Screen name="OpenTables" component={OrdersScreen}
                            options={{
                                tabBarIcon: ({color, size}) => (
                                    <Icon name="cutlery" color={color} size={size}/>
                                ),
                                title: 'Nyitott asztalok',
                                tabBarLabel: 'Nyitott asztalok',
                                tabBarBadge: statistics?.dineInOrders?.length || 0,
                                tabBarBadgeStyle: statistics?.dineInOrders?.length === 0 ? { display: 'none' } : {}
                            }}
                            initialParams={{ name: "OpenTables" }}
                />
                <Tab.Screen name="Bookings" component={TablesBookingsScreen}
                            options={{
                                tabBarIcon: ({color, size}) => (
                                    <Icon name="calendar" color={color} size={size}/>
                                ),
                                title: 'Közelgő foglalások',
                                tabBarLabel: 'Foglalások'
                            }}/>
                <Tab.Screen name="Functions" component={FunctionsScreen}
                            options={{
                                tabBarIcon: ({ color, size }) => (
                                    <Icon name="server" color={color} size={size} />
                                ),
                                title: 'Funkciók és műveletek',
                                tabBarLabel: 'Funkciók',
                            }} />
            </Tab.Navigator>

            <CircularButton navigation={navigation} iconName="grid" />
        </View>
    );
}

const styles = StyleSheet.create({
    background: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});
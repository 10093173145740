import React, { createContext, useState } from 'react';

export const VipCardContext = createContext();

export const VipCardProvider = ({ children }) => {
    const [selectedVipCard, setSelectedVipCard] = useState(null);

    const selectVipCard = (vipCard) => {
        setSelectedVipCard(vipCard);
    };

    const removeVipCard = () => {
        setSelectedVipCard(null);
    };

    return (
        <VipCardContext.Provider value={{ selectedVipCard, selectVipCard, removeVipCard }}>
            {children}
        </VipCardContext.Provider>
    );
};
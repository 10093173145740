import React, {useContext, useEffect, useState, } from "react";
import {View, Text, StyleSheet, TouchableOpacity, ScrollView, TextInput, Modal} from "react-native";
import {OrdersContext} from "../helpers/OrdersContext";
import {useNavigation} from "@react-navigation/native";
import OrderRow from "../components/OrderRow";
import {LinearGradient} from "expo-linear-gradient";
import { HappyDialog } from '../helpers/crossPlatformTools/dialogs';
import HappyAlert from "../helpers/crossPlatformTools/HappyAlert";

const VipUserDetailScreen = ({ route }) => {
    const { vipCard } = route.params;
    const [selectedTab, setSelectedTab] = useState("open"); // Tab selection state
    const {orders} = useContext(OrdersContext);
    const [openOrders, setOpenOrders] = useState([]);
    const [closedOrders, setClosedOrders] = useState([]);
    const navigation = useNavigation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [isDialogVisible, setIsDialogVisible] = useState(false);

    useEffect(() => {
        console.log(vipCard);
        console.log(orders)
    }, [route]);

    const handleOpenModal = () => {
        setIsModalVisible(true);
    };

    const handleCloseModal = () => {
        setIsModalVisible(false);
    };


    const handleConfirm = async () => {
        try {
            // Placeholder fetch that always fails
            const response = await fetch('https://example.com/payment.....', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ amount: inputValue }),
            });

            if (!response.ok) {
                throw new Error('Payment failed');
            }

            // If payment is successful
            setIsDialogVisible({
                visible: true,
                type: 'info',
                message: 'Sikeres befizetés!',
                confirmText: 'OK',
                onConfirm: () => {
                    setIsModalVisible(false)
                    setIsDialogVisible( {visible: false})
                },
            });
        } catch (error) {
            // If payment fails
            setIsDialogVisible({
                visible: true,
                type: 'error',
                message: 'Fizetés sikertelen, próbálja újra később!',
                confirmText: 'OK',
                onConfirm: () => {
                    setIsModalVisible(false)
                    setIsDialogVisible( {visible: false})
                },
            });
        }
    };

    useEffect(() => {
        console.log("orders:", orders);
        console.log("openOrders", openOrders);
        console.log("closedOrders", closedOrders);
        console.log("selectedTab", selectedTab);

    }, [selectedTab, openOrders, closedOrders]);

    useEffect(() => {
        filterOrders();
    }, [orders, vipCard]);

    const filterOrders = () => {
        const open = orders.filter(order => order.VIPCard?._id === vipCard._id && order.status !== "closed" && !order.storno);
        const closed = orders.filter(order => order.VIPCard?._id === vipCard._id && (order.status === "closed" || order.storno || order.status === "cancelled"));
        setOpenOrders(open);
        setClosedOrders(closed);
    };

    const handleOrderPress = (order) => {
        navigation.navigate('OrderDetailsScreen', { orderData: order });
    };

    // Function to determine balance background color
    const getBalanceColor = (balance) => {
        return balance >= 0 ? styles.balancePositive : styles.balanceNegative;
    };

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{width: '100%', height: '95svh'}}
        >
        <ScrollView contentContainerStyle={styles.container}>
            <View style={styles.topSection}>
                <View style={styles.leftColumn}>
                    <View style={[styles.balanceContainer, getBalanceColor(vipCard.balance)]}>
                        <Text style={styles.balanceText}>Egyenleg: {vipCard.balance} Ft</Text>
                    </View>
                    <TouchableOpacity style={[styles.balanceContainer2, {backgroundColor: "#F1B34B", alignSelf: "center" }]}
                                      onPress={handleOpenModal} >
                        <Text style={styles.balanceText}>Befizetés</Text>
                    </TouchableOpacity>
                    <View style={styles.infoContainer}>
                        <View style={styles.row}>
                            <Text style={styles.boldText}>Név: {vipCard.user.name.first} {vipCard.user.name.last}</Text>
                            <Text style={styles.boldText}>#{vipCard?.cardId || 'N/A'}</Text>
                        </View>
                        <Text style={styles.infoText}>Telefon: {vipCard?.user.phoneNumbers[0]?.phone || 'N/A'}</Text>
                        <Text style={styles.infoText}>Email: {vipCard?.user?.email}</Text>
                    </View>
                </View>

                <View style={styles.rightColumn}>
                    <View style={styles.detailsContainer}>
                        <Text style={styles.infoText}>Kedvezmény: {vipCard.discountAmount}%</Text>
                        <Text style={styles.infoText}>Szolgáltatási Díj: {vipCard.enableServiceFee ? 'Igen' : 'Nem'}</Text>
                        <Text style={styles.infoText}>Létrehozva: {new Date(vipCard.created).toLocaleString()}</Text>
                        <Text style={styles.infoText}>Utoljára használva: {new Date(Math.max(...vipCard.history.map(item => new Date(item.time)))).toLocaleString()}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.ordersContainer}>
                <View style={styles.tabContainer}>
                    <TouchableOpacity
                        style={[styles.tabButton, selectedTab === 'open' && styles.activeTab]}
                        onPress={() => setSelectedTab('open')}
                    >
                        <Text style={styles.tabText}>Nyitott számlák</Text>
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.tabButton, selectedTab === 'closed' && styles.activeTab]}
                        onPress={() => setSelectedTab('closed')}
                    >
                        <Text style={styles.tabText}>Lezárt számlák</Text>
                    </TouchableOpacity>
                </View>

                <View style={styles.orderList}>
                    <ScrollView>
                        {selectedTab === 'open' ? (
                            openOrders.map(order => (
                                <OrderRow key={order._id} order={order} onPress={handleOrderPress} />
                            ))
                        ) : (
                            closedOrders.map(order => (
                                <OrderRow key={order._id} order={order} onPress={handleOrderPress} />
                            ))
                        )}
                    </ScrollView>
                </View>

            </View>
            <Modal
                animationType="slide"
                transparent={true}
                visible={isModalVisible}
                onRequestClose={handleCloseModal}
            >
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalTitle}>Befizetés</Text>
                        <TextInput
                            style={styles.input}
                            placeholder="Írjon be egy összeget"
                            value={inputValue}
                            onChangeText={setInputValue}
                            keyboardType="numeric"
                            maxLength={10}
                        />
                        <View style={styles.modalButtons}>
                            <TouchableOpacity style={styles.modalButton} onPress={handleCloseModal}>
                                <Text style={styles.buttonText}>Mégsem</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.modalButton} onPress={handleConfirm}>
                                <Text style={styles.buttonText}>Befizetés</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
            <HappyAlert
                visible={isDialogVisible.visible}
                type={isDialogVisible.type}
                message={isDialogVisible.message}
                confirmText={isDialogVisible.confirmText}
                onConfirm={isDialogVisible.onConfirm}
            />
        </ScrollView>

        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        padding: 16,
    },
    topSection: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 16,
    },
    orderItem: {
        padding: 10,
        backgroundColor: '#fff',
        marginBottom: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ddd',
    },
    leftColumn: {
        width: '49%',
    },
    rightColumn: {
        width: '49%',
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 8,
    },
    boldText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#333',
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 1000
    },
    modalContent: {
        width: 300,
        padding: 20,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    input: {
        width: '100%',
        padding: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 5,
        marginBottom: 20,
    },
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    modalButton: {
        flex: 1,
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginHorizontal: 5,
        backgroundColor: '#007BFF',
    },
    buttonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    balanceContainer: {
        paddingHorizontal: 18,
        paddingVertical: 12,
        borderRadius: 8,
        marginBottom: 6,
        alignItems: 'center',
    },
    balanceContainer2: {
        paddingHorizontal: 18,
        paddingVertical: 9,
        borderRadius: 8,
        marginBottom: 12,
        alignItems: 'center',
    },
    balancePositive: {
        backgroundColor: '#8BC34A',
    },
    balanceNegative: {
        backgroundColor: '#F44336',
    },
    balanceText: {
        fontSize: 22,
        fontWeight: 'bold',
        color: '#fff',
    },
    infoContainer: {
        backgroundColor: '#fff8f3',
        padding: 16,
        borderRadius: 8,
    },
    detailsContainer: {
        backgroundColor: '#fff8f3',
        padding: 16,
        borderRadius: 8,
        flex:1,
        justifyContent: "center"
    },
    infoText: {
        fontSize: 16,
        color: '#333',
        marginBottom: 8,
    },
    ordersContainer: {
        backgroundColor: '#fff8f3',
        padding: 16,
        borderRadius: 8,
        flex: 1
    },
    tabContainer: {
        flexDirection: 'row',
        marginBottom: 12,
    },
    tabButton: {
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: '#F1B34B',
        borderRadius: 8,
        marginRight: 8,
    },
    activeTab: {
        backgroundColor: '#FF8000FF',
        borderWidth: 1,
        borderColor: '#D66E0EFF',
    },
    tabText: {
        fontSize: 16,
        fontWeight: 'bold',
        color: '#fdf8f3',
    },
    orderList: {
        backgroundColor: '#fff1e9',
        padding: 10,
        borderRadius: 5,
        minHeight: "60px",
    },
    placeholderText: {
        fontSize: 16,
        textAlign: 'center',
        color: '#757575',
    },
});

export default VipUserDetailScreen;

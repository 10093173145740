import React, {useState} from 'react';
import {
    Image,
    Linking, Platform,
    SafeAreaView,
    ScrollView,
    StatusBar,
    StyleSheet,
    Switch,
    Text,
    TouchableOpacity,
    View
} from 'react-native';
import FeatherIcon from 'react-native-vector-icons/Feather';
import {LinearGradient} from "expo-linear-gradient";
import {getAccessToken, getUserInfo, removeAccessToken, removeUserInfo} from "../helpers/storage";
import AsyncStorage from "@react-native-async-storage/async-storage";
import appConfig from '../../app.json';
import HappyPrompt from "../helpers/crossPlatformTools/HappyPrompt";
import HappyAlert from "../helpers/crossPlatformTools/HappyAlert";
const { version } = appConfig.expo;

const DEVELOPER_MODE_KEY = 'developerModeTimestamp';

export default function SettingsScreen({navigation}) {
    const [form, setForm] = useState({
        emailNotifications: false,
        pushNotifications: true,
        smsNotifications: false,
    });
    const [enablePhoneLinking, setEnablePhoneLinking] = useState(false);
    const [restaurantPlace, setRestaurantPlace] = useState({name: '', raw: ''});
    const [currentUser, setCurrentUser] = useState({name: {first: '', last: ''}, email: '', photo: ''});
    const [accessToken, setAccessToken] = useState('');
    const [developerPINError, setDeveloperPINError] = useState(false);
    const [developerPromptVisible, setDeveloperPromptVisible] = useState(false);
    const [isDeveloper, setIsDeveloper] = useState(true); // TODO: For temporary use only, we must implement a secure 3ds security here

    React.useEffect(() => {
        const checkDeveloperMode = async () => {
            const timestamp = await AsyncStorage.getItem(DEVELOPER_MODE_KEY);
            if (timestamp) {
                const elapsed = Date.now() - parseInt(timestamp, 10);
                if (elapsed < 24 * 60 * 1000) { // 60 minutes in milliseconds
                    setIsDeveloper(true);
                    const remainingTime = 24 * 60 * 10000 - elapsed;
                    setTimeout(() => setIsDeveloper(false), remainingTime);
                } else {
                    await AsyncStorage.removeItem(DEVELOPER_MODE_KEY);
                }
            }
        };
        checkDeveloperMode();
    }, []);

    React.useEffect(() => {
        if (isDeveloper) {
            AsyncStorage.setItem(DEVELOPER_MODE_KEY, new Date().getTime().toString());
            const timer = setTimeout(async () => {
                setIsDeveloper(false);
                await AsyncStorage.removeItem(DEVELOPER_MODE_KEY);
            }, 600000); // 10 minutes in milliseconds

            return () => clearTimeout(timer);
        }
    }, [isDeveloper]);

    React.useEffect(() => {
        const setDatas = async () => {
            const restaurantPlaceStored = await AsyncStorage.getItem("restaurantPlace");
            setRestaurantPlace(JSON.parse(restaurantPlaceStored));

            const userInfo = await getUserInfo();
            setCurrentUser(userInfo);

            const token = await getAccessToken();
            setAccessToken(token);

            if(await AsyncStorage.getItem("isDeveloper") === "true") setIsDeveloper(true);
        }
        setDatas();
    }, [navigation])

    const openPhoneCall = () => {
        const phoneNumber = 'tel:+3613009105'; // Replace with your phone number
        Linking.canOpenURL(phoneNumber)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(phoneNumber);
                } else {
                    console.log("Don't know how to open URI: " + phoneNumber);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };

    const openEmail = (subject = "Üzenet az alkalmazásból (v"+version+")") => {
        const email = 'mailto:support@happygastro.hu/?subject=' + subject; // Replace with your email address
        Linking.canOpenURL(email)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(email);
                } else {
                    console.log("Don't know how to open URI: " + email);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };
    const openAdmin = async () => {
        const link = 'https://admin.happygastro.hu/?token='+ await getAccessToken(); // Replace with your email address
        Linking.canOpenURL(link)
            .then((supported) => {
                if (supported) {
                    Linking.openURL(link);
                } else {
                    console.log("Don't know how to open URI: " + link);
                }
            })
            .catch((err) => console.error('An error occurred', err));
    };

    React.useEffect(() => {
        return () => {
            setDeveloperPromptVisible(false);
            setIsDeveloper(false);
        }
    }, []);

    React.useEffect(() => {
        if(Platform.OS === 'web') return setEnablePhoneLinking(false);
        const phoneNumber = 'tel:+3613009105'; // Replace with your phone number
        Linking.canOpenURL(phoneNumber)
            .then((supported) => {
                if (supported) {
                    setEnablePhoneLinking(true)
                } else {
                    setEnablePhoneLinking(false)
                }
            })
            .catch((err) => setEnablePhoneLinking(false));
    }, [navigation])

    return (
        <View style={{width: '100%', height: '100vh', flexGrow: 1, overflow: 'scroll'}}>
        <LinearGradient
            // Replace colors with the ones matching the image you provided
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}  // Example gradient colors
            style={{width: '100%', height: '100vh', flexGrow: 1, overflow: 'scroll', paddingBottom: 60}}
        >
            <HappyAlert
                visible={developerPINError}
                onConfirm={() => {
                    setDeveloperPINError(false);
                    setDeveloperPromptVisible(true);
                }}
                onCancel={() => {
                    setDeveloperPINError(false);
                    setDeveloperPromptVisible(false);
                }}
                type="error"
                message="A beírt PIN kód nem megfelelő. Szertnéd újra megpróbálni?"
                confirmText="Igen"
                cancelText="Nem"
            />

            <HappyPrompt
                visible={developerPromptVisible}
                onConfirm={(value) => {
                    if (value === "4599") {
                        setIsDeveloper(true);
                        setDeveloperPromptVisible(false);
                    } else {
                        setDeveloperPromptVisible(false);
                        setDeveloperPINError(true);
                    }
                }}
                onCancel={() => setDeveloperPromptVisible(false)}
                icon="info"
                title="Szervíz beállítások"
                message="Ezekhez a beállításokhoz szervíz jogosultság szükséges biztonsági okokból!"
                confirmText="Belépés"
                cancelText="Mégse"
                inputProps={{ textContentType: 'oneTimeCode', returnKeyType: 'send', returnKeyLabel: 'Belépés', keyboardType: 'numeric', placeholder: 'Szervíz PIN kód' }}
            />

            <SafeAreaView style={{flex: 1, backgroundColor: 'transparent', paddingTop: 10, overflow: 'scroll', height: '100%', flexGrow: 1}}>
            <StatusBar backgroundColor="transparent" translucent barStyle="dark-content"/>
            <View style={styles.header}>
                <View style={styles.headerAction}>
                </View>

                <Text numberOfLines={1} style={styles.headerTitle}>
                    Fiókom és beállítás
                </Text>

                {false && <View style={[styles.headerAction, {alignItems: 'flex-end'}]}>
                    <TouchableOpacity
                        onPress={() => {
                            // handle onPress
                        }}>
                        <FeatherIcon
                            color="#000"
                            name="more-vertical"
                            size={24}/>
                    </TouchableOpacity>
                </View>}
            </View>

            <ScrollView contentContainerStyle={styles.content}>
                <View style={[styles.section, {paddingTop: 4}]}>
                    <Text style={styles.sectionTitle}>Profilom</Text>

                    <View style={styles.sectionBody}>
                        <TouchableOpacity
                            activeOpacity={.9}
                            onPress={() => {
                                // handle onPress
                            }}
                            style={styles.profile}>
                            <Image
                                alt=""
                                source={{
                                    uri: currentUser ? currentUser.photo : 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/default-avatar.png',
                                }}
                                defaultSource={{uri: 'https://happy-releases.fra1.cdn.digitaloceanspaces.com/default-avatar.png'}}
                                style={styles.profileAvatar}/>

                            <View style={styles.profileBody}>
                                <Text style={styles.profileName}>{currentUser.name.first} {currentUser.name.last}</Text>

                                <Text style={styles.profileHandle}>{currentUser.email}</Text>
                            </View>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={22}/>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Segítség</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                accessibilityRole="link"
                                onPress={() => {
                                    navigation.navigate("SupportChatScreen")
                                    //openEmail("Észrevétel az alkalmazásból");
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Ügyfélszolgálat, hibabejelentés</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <TouchableOpacity
                                accessibilityRole="link"
                                onPress={() => {
                                    // handle onPress
                                    openAdmin()
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Management felület</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>


                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Megjelenés</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                onPress={() => {
                                    // handle onPress
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Nyelv</Text>

                                <View style={styles.rowSpacer}/>

                                <Text style={styles.rowValue}>Magyar</Text>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={[styles.rowWrapper]}>
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={() => {
                                // handle onPress
                                navigation.navigate("DesignSettingsScreen")
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Értékesítési felület</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>

                    <View style={[styles.rowWrapper, styles.rowLast]}>
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={() => {
                                // handle onPress
                                //navigation.navigate("DesignSettingsScreen")
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Egyéb beállítások</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Értesítések</Text>

                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>E-mail értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={emailNotifications =>
                                        setForm({...form, emailNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}}
                                    trackColor={{false: '#767577', true: '#f3c498'}}
                                    thumbColor={'#fca34c'}
                                    ios_backgroundColor="#fca34c"
                                    value={form.emailNotifications}/>
                            </View>
                        </View>

                        <View style={[styles.rowWrapper]}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>Push értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={pushNotifications =>
                                        setForm({...form, pushNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}}
                                    trackColor={{false: '#767577', true: '#f3c498'}}
                                    thumbColor={'#fca34c'}
                                    ios_backgroundColor="#fca34c"
                                    value={form.pushNotifications}/>
                            </View>
                        </View>

                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <View style={styles.row}>
                                <Text style={styles.rowLabel}>SMS értesítések</Text>

                                <View style={styles.rowSpacer}/>

                                <Switch
                                    onValueChange={smsNotifications =>
                                        setForm({...form, smsNotifications})
                                    }
                                    style={{transform: [{scaleX: 0.95}, {scaleY: 0.95}]}}
                                    trackColor={{false: '#767577', true: '#f3c498'}}
                                    thumbColor={'#fca34c'}
                                    ios_backgroundColor="#fd9b37"
                                    value={form.smsNotifications}/>
                            </View>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Üzlet és eszköz beállítások</Text>
                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                accessibilityRole="none"
                                activeOpacity={1}
                                onPress={() => {
                                    // handle onPress
                                    // TODO: Open place selector like at login
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, {textAlign: 'center', fontWeight: "700"}]}>{restaurantPlace.name || restaurantPlace.raw}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={[styles.rowWrapper, styles.rowLast]}>
                        <TouchableOpacity
                            accessibilityRole="button"
                            onPress={() => {
                                // handle onPress
                                navigation.navigate("DesignSettingsScreen")
                            }}
                            style={styles.row}>
                            <Text style={styles.rowLabel}>Használat és kényelem</Text>

                            <View style={styles.rowSpacer}/>

                            <FeatherIcon
                                color="#bcbcbc"
                                name="chevron-right"
                                size={19}/>
                        </TouchableOpacity>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Elérhetőségeink</Text>
                    <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                accessibilityRole="link"
                                onPress={() => {
                                    if (enablePhoneLinking) openPhoneCall()
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, {color: enablePhoneLinking ? "#a69f9f" : "#a69f9f"}]}>Hívj
                                    minket telefonon!</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>

                        <View style={[styles.rowWrapper]}>
                            <TouchableOpacity
                                accessibilityRole="none"
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("PrivacyPolicyScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Adatvédelmi nyilatkozat</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <TouchableOpacity
                                accessibilityRole="none"
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("TermsOfServicesScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Felhasználási feltételek</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>

                <View style={styles.section}>
                    <Text style={styles.sectionTitle}>Speciális beállítások</Text>
                    {!isDeveloper && <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst, styles.rowLast]}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => {
                                    // handle onPress
                                    //setIsDeveloper(true);
                                    setDeveloperPromptVisible(true);
                                    // TODO: New Prompt helper from @gagorobert
                                    //return Alert.prompt("Szervíz jelszó:", "Kérjük, adja meg az aktuális szervízjelszót!")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Belépés a beállításokba</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>}

                    {isDeveloper && <View style={styles.sectionBody}>
                        <View style={[styles.rowWrapper, styles.rowFirst]}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("ScanDevicesScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Vezeték nélküli eszközök</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>

                        <View style={[styles.rowWrapper, styles.rowWrapper]}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => {
                                    // handle onPress
                                    navigation.navigate("SettingsPrinterPairingScreen")
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Nyomtatók és profilok</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                        <View style={[styles.rowWrapper, styles.rowLast]}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                onPress={() => {
                                    navigation.navigate("PaymentsSettingsScreen");
                                }}
                                style={styles.row}>
                                <Text style={styles.rowLabel}>Fizetési és adóügyi eszközök</Text>

                                <View style={styles.rowSpacer}/>

                                <FeatherIcon
                                    color="#bcbcbc"
                                    name="chevron-right"
                                    size={19}/>
                            </TouchableOpacity>
                        </View>
                    </View>}
                </View>

                <Text style={styles.contentFooter}>Alkalmazás verzió v{version}</Text>

                <View style={[styles.section, {paddingBottom: 30}]}>
                    <View style={styles.sectionBody}>
                        <View
                            style={[
                                styles.rowWrapper,
                                styles.rowFirst,
                                styles.rowLast,
                                {alignItems: 'center'},
                            ]}>
                            <TouchableOpacity
                                accessibilityRole="button"
                                onPress={async () => {
                                    await removeUserInfo();
                                    await removeAccessToken();



                                    return navigation.navigate("StartScreen");
                                }}
                                style={styles.row}>
                                <Text style={[styles.rowLabel, styles.rowLabelLogout]}>
                                    Kijelentkezés
                                </Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </ScrollView>
        </SafeAreaView>
        </LinearGradient></View>
    );
}

const styles = StyleSheet.create({
    /** Header */
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        paddingHorizontal: 16,
    },
    headerAction: {
        width: 40,
        height: 40,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    headerTitle: {
        fontSize: 19,
        fontWeight: "600",
        color: '#000',
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
        textAlign: 'center',
    },
    /** Content */
    content: {
        paddingHorizontal: 16,
        backgroundColor: 'transparent',
    },
    contentFooter: {
        marginTop: 0,
        fontSize: 13,
        fontWeight: '500',
        textAlign: 'center',
        color: '#a69f9f',
    },
    /** Section */
    section: {
        paddingVertical: 12,
    },
    sectionTitle: {
        margin: 8,
        marginLeft: 12,
        fontSize: 13,
        letterSpacing: 0.33,
        fontWeight: '500',
        color: '#a69f9f',
        textTransform: 'uppercase',
    },
    sectionBody: {
        borderRadius: 12,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.5,
        shadowRadius: 1.41,
        elevation: 3,
    },
    /** Profile */
    profile: {
        padding: 12,
        backgroundColor: '#FEFEFE',
        borderRadius: 12,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
    profileAvatar: {
        width: 60,
        height: 60,
        borderRadius: 9999,
        marginRight: 12,
    },
    profileBody: {
        marginRight: 'auto',
    },
    profileName: {
        fontSize: 18,
        fontWeight: '600',
        color: '#292929',
    },
    profileHandle: {
        marginTop: 2,
        fontSize: 16,
        fontWeight: '400',
        color: '#858585',
    },
    /** Row */
    row: {
        height: 44,
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        paddingRight: 12,
    },
    rowWrapper: {
        paddingLeft: 16,
        backgroundColor: '#fff',
        borderTopWidth: 1,
        borderColor: '#f0f0f0',
    },
    rowFirst: {
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
    },
    rowLabel: {
        fontSize: 16,
        letterSpacing: 0.24,
        color: '#000',
    },
    rowSpacer: {
        flexGrow: 1,
        flexShrink: 1,
        flexBasis: 0,
    },
    rowValue: {
        fontSize: 16,
        fontWeight: '500',
        color: '#ababab',
        marginRight: 4,
    },
    rowLast: {
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
    },
    rowLabelLogout: {
        width: '100%',
        textAlign: 'center',
        fontWeight: '600',
        color: '#dc2626',
    },
});

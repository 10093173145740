import React, { useContext, useState } from 'react';
import { TouchableOpacity, StyleSheet, View, Alert, Platform } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Menu } from 'react-native-paper';
import { CartContext } from '../helpers/CartContext';
import useConfirmNavigation from '../helpers/UseConfirmNavigation';
import { TableContext } from "../helpers/TableContext";
import { OnlineOrderContext } from "../helpers/OnlineOrderContext";

const CircularButton = ({ navigation, iconName, style }) => {
    const [menuVisible, setMenuVisible] = useState(false);
    const { cartItems, setAlreadySentCartItems } = useContext(CartContext);
    const { removeTable } = useContext(TableContext);
    const { orderData, updateOrderData } = useContext(OnlineOrderContext);
    const confirmNavigation = useConfirmNavigation(navigation);

    const handleSelect = (value) => {
        setMenuVisible(false);
        const currentRoute = navigation.getState().routes[navigation.getState().index].name;

        const navigateToScreen = (screen) => {
            if (cartItems.length > 0) {
                confirmNavigation(screen);
            } else {
                removeTable();
                setAlreadySentCartItems([]);
                navigation.navigate(screen);
            }
        };

        const showAlert = () => {
            if (Platform.OS === 'web') {
                if (window.confirm("Ha most átlép másik felületre félbe hagyja az online rendelés leadását")) {
                    updateOrderData({
                        // Reset other order data fields as needed
                    }, '');
                    navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
                }
            } else {
                Alert.alert(
                    "Figyelem",
                    "Ha most átlép másik felületre félbe hagyja az online rendelés leadását",
                    [
                        {
                            text: "Mégsem",
                            style: "cancel"
                        },
                        {
                            text: "Igen",
                            onPress: () => {
                                updateOrderData({
                                    userInfo: null,
                                    addressInfo: null,
                                    // Reset other order data fields as needed
                                });
                                navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
                            }
                        }
                    ]
                );
            }
        };

        const navigateToScreenBasedOnValue = (value, currentRoute, navigateToScreen) => {
            if (value === 'takeAway') {
                if (currentRoute !== 'HomeScreen') {
                    navigateToScreen('HomeScreen');
                }
            } else if (value === 'dineIn') {
                if (currentRoute !== 'TablesScreen') {
                    navigateToScreen('TablesScreen');
                }
            } else if (value === 'delivery') {
                if (currentRoute !== 'OnlineScreen') {
                    navigateToScreen('OnlineScreen');
                }
            }
        };

        if (orderData?.userInfo && orderData?.addressInfo) {
            showAlert();
        } else {
            navigateToScreenBasedOnValue(value, currentRoute, navigateToScreen);
        }
    };

    return (
        <View style={styles.menuContainer}>
            <Menu
                visible={menuVisible}
                onDismiss={() => setMenuVisible(false)}
                anchor={
                    <TouchableOpacity
                        activeOpacity={0.6}
                        onPress={() => setMenuVisible(true)}
                        style={[styles.circularButton, style]}
                    >
                        <Ionicons name={iconName} size={30} style={styles.icon} />
                    </TouchableOpacity>
                }
                contentStyle={styles.menuContent}
                style={styles.menu}
            >
                <Menu.Item titleStyle={{ color: '#000', fontWeight: "900" }} contentStyle={{ color: '#000' }} color={"#000"} rippleColor={'rgba(185,185,185,0.3)'} onPress={() => handleSelect('takeAway')} title="Gyorsnyugta" leadingIcon="food-takeout-box" />
                <Menu.Item titleStyle={{ color: '#F1B44C', fontWeight: "900" }} contentStyle={{ color: '#F1B44C' }} color={"#F1B44C"} rippleColor={"rgba(255,215,151,0.3)"} onPress={() => handleSelect('dineIn')} title="Asztaltérkép" leadingIcon="silverware-fork-knife" />
                <Menu.Item titleStyle={{ color: '#50A5F1', fontWeight: "900" }} contentStyle={{ color: '#50A5F1' }} color={'#50A5F1'} rippleColor={'rgba(154,203,248,0.3)'} onPress={() => handleSelect('delivery')} title="Házhozszállítás" leadingIcon="truck-delivery" />
            </Menu>
        </View>
    );
};

const styles = StyleSheet.create({
    circularButton: {
        margin: "auto",
        width: 50,
        height: 50,
        borderRadius: 25,
        backgroundColor: '#F1B34B',
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 5,
        zIndex: 99999999999999, // Ensure the button is above other elements
    },
    menuContainer: {
        position: 'absolute',
        bottom: 40,
        left: '50%',
        transform: [{ translateX: -25 }],
        zIndex: 9999999999, // Ensure the menu is above other elements
    },
    menuContent: {
        paddingVertical: 0,
        backgroundColor: '#fff',
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.40,
        shadowRadius: 1.41,
        elevation: 2,
        zIndex: 20, // Ensure the menu is above the button
    },
    menu: {
        width: 240,
        position: 'absolute',
        backgroundColor: '#fff',
        left: '50%',
        transform: [{ translateX: -120 }, { translateY: -50 }], // Adjust translateX to half of the menu width
    },
    fixedIcon: {
        marginLeft: 'auto',
        marginRight: 5,
        top: 10,
    },
    icon: {
        color: '#fff',
    },
});

export default CircularButton;
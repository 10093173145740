import React, { createContext, Component } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Platform } from 'react-native';
import { getAccessToken } from './storage';
import Observables from "@happy-gastro/global-observables";

export const SystemContext = createContext();

class SystemProvider extends Component {
    state = {
        foods: [],
        categories: [],
        events: [],
        restaurant: null,
        restaurantPlace: null,
        loaded: false,
        defaultView: 'categories',
        eventColumnCount: 2,
        categoryColumnCount: 2,
        selectedEvent: null,
        selectedCategory: null,
        token: null,
        vipCards: [],
        bookings: []
    };

    fetchStoredDatas = async () => {
        const storedFoods = await AsyncStorage.getItem('foods');
        const storedCategories = await AsyncStorage.getItem('categories');
        const storedEvents = await AsyncStorage.getItem('events');

        if (storedFoods) this.setState({ foods: JSON.parse(storedFoods) });
        if (storedCategories) this.setState({ categories: JSON.parse(storedCategories) });
        if (storedEvents) this.setState({ events: JSON.parse(storedEvents) });
    }

    fetchSystemData = async () => {
        const { token } = this.state;
        const restaurantData = await AsyncStorage.getItem('restaurant');
        const restaurantPlaceData = await AsyncStorage.getItem('restaurantPlace');
        const storedDefaultView = await AsyncStorage.getItem('defaultView');
        const storedEventColumnCount = await AsyncStorage.getItem('eventColumnCount');
        const storedCategoryColumnCount = await AsyncStorage.getItem('categoryColumnCount');

        if (!token || !restaurantData || !restaurantPlaceData) return;

        await this.fetchStoredDatas();

        try {
            const [foodsResponse, categoriesResponse, vipCardsResponse, bookingsResponse] = await Promise.all([
                fetch('https://api.happygastro.hu/api/restaurant/' + JSON.parse(restaurantData)._id + '/foods/mobile?placeId=' + JSON.parse(restaurantPlaceData)._id + '&lowKey=true&beta=true&showDisabled=false', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
                fetch('https://api.happygastro.hu/api/restaurant/' + JSON.parse(restaurantData)._id + '/categories/mobile?onlyVisible=false&lowKey=true&beta=true', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
                fetch('https://api.happygastro.hu/api/vipcard/list', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
                fetch('https://api.happygastro.hu/api/booking/restaurant', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                }),
            ]);

            const foodsData = await foodsResponse.json();
            const categoriesData = await categoriesResponse.json();
            const vipCardsData = await vipCardsResponse.json();
            const bookingsData = await bookingsResponse.json();

            if (JSON.stringify(foodsData.filter(food => food.enabled))) AsyncStorage.setItem('foods', JSON.stringify(foodsData.filter(food => food.enabled)));
            if (categoriesData.filter(category => category.visible && category.enabled && !category.name.hu.toLowerCase().includes('törölt'))) AsyncStorage.setItem('categories', JSON.stringify(categoriesData.filter(category => category.visible && category.enabled && !category.name.hu.toLowerCase().includes('törölt'))));

            this.setState({
                restaurant: JSON.parse(restaurantData),
                restaurantPlace: JSON.parse(restaurantPlaceData),
                foods: foodsData.filter(food => food.enabled),
                categories: categoriesData.filter(category => category.visible && category.enabled && !category.name.hu.toLowerCase().includes('törölt')),
                vipCards: vipCardsData,
                bookings: bookingsData,
                loaded: true
            });

            let eventsData = {};
            try {
                const eventsResponse = await fetch('https://api.happygastro.hu/api/tickets/events/all', {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token }
                });
                eventsData = await eventsResponse.json();
                if (eventsData) AsyncStorage.setItem('events', JSON.stringify(eventsData));

                this.setState({ events: eventsData.events, loaded: true });
            } catch (eventsFetchError) {
                this.setState({ loaded: true });
                console.error("Events fetch error", eventsFetchError);
            }
        } catch (error) {
            console.error("Failed to fetch system data", error);
            await this.fetchStoredDatas();
            this.setState({ loaded: true });
        }
    };

    async componentDidMount() {
        const token = await getAccessToken();
        this.setState({ token });
        Observables.register('fetchSystemData', () => new Promise(() => this.fetchSystemData()));
        this.fetchSystemData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.token !== this.state.token) {
            this.fetchSystemData();
        }
    }

    render() {
        const { children } = this.props;
        return (
            <SystemContext.Provider value={{
                ...this.state,
                fetchSystemData: this.fetchSystemData,
                setSelectedCategory: category => this.setState({ selectedCategory: category }),
                setSelectedEvent: event => this.setState({ selectedEvent: event }),
            }}>
                {children}
            </SystemContext.Provider>
        );
    }
}

export default React.memo(SystemProvider);
import React from 'react';
import {ScrollView, StyleSheet, Text, TouchableOpacity, View, Alert} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import IonIcons from 'react-native-vector-icons/Ionicons';
import {LinearGradient} from "expo-linear-gradient";
import MaterialCommunityIcons from "react-native-vector-icons/MaterialCommunityIcons";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {FontAwesome, FontAwesome5, MaterialIcons} from "@expo/vector-icons";

const FunctionsScreen = (props) => {
    const {navigation} = props;

    const [isFiscal, setIsFiscal] = React.useState(false);
    React.useEffect(() => {
        const getIsFiscal = async () => setIsFiscal(await AsyncStorage.getItem('selectedCashierDevice'))
        getIsFiscal();

        return () => setIsFiscal(false);
    }, []);

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{flex: 1, width: '100%', height: '100%'}}
        >
            <ScrollView style={{paddingBottom: 50}}>
                <View style={styles.container}>
                    <View style={{width: "100%", marginTop: 30, padding: 15}}>
                        <View style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}><Text
                            style={{
                                fontWeight: "bold",
                                textAlign: "left",
                                fontSize: 17,
                            }}
                        >
                            Napi kimutatások és pénztárgép funkciók
                        </Text>
                            <TouchableOpacity activeOpacity={0.5} onPress={() => navigation.navigate("SettingsScreen")}>
                                <IonIcons name={"settings"} color={"#000"} size={30} style={{marginTop: 10}}/>
                            </TouchableOpacity>
                        </View>
                        <Text style={{textAlign: "left", marginTop: 15, borderRadius: 10}}>
                            Az alábbi felületen érhetőek el a napi kimutatások, a nap zárása, az egyéb pénztárgép funkciók, valamint a készletkezelési lehetőségek.
                        </Text>
                    </View>
                    <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                        <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                            Forgalmi kimutatás
                        </Text>
                        <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15, padding: 20,}}>
                            <TouchableOpacity activeOpacity={0.7} style={[styles.box, {width: '49%'}]} onPress={() => navigation.navigate("StatisticsScreen")}>
                                <View style={[styles.boxInner, {flexDirection: 'row'}]}>
                                    <Icon name="chart-line" size={30} color="#000" style={{marginRight: 30}}/>
                                    <Text style={[styles.boxTitle, {marginTop: 0}]}>Napi forgalom</Text>
                                </View>
                            </TouchableOpacity>
                            <TouchableOpacity activeOpacity={0.7} style={[styles.box, {width: '49%', backgroundColor: '#f1b44c', padding: 20,}]} onPress={() => navigation.navigate("DayClosingScreen")}>
                                <View style={[styles.boxInner, {flexDirection: 'row'}]}>
                                    <Icon name="lock" size={30} color="#fff" style={{marginRight: 30}}/>
                                    <Text style={[styles.boxTitle, {marginTop: 0, color: 'white'}]}>Pénzügyi nap zárása</Text>
                                </View>
                            </TouchableOpacity>
                        </View>
                    </View>
                    {isFiscal && <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Pénztárgép funkciók
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="feed" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Státusz lekérdezése</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <Icon name="chart-line" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Napi lekérdezés</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.box}>
                            <View style={styles.boxInner}>
                            <MaterialIcons name="table" size={30} color="#000" style={{marginRight: 15}}/>
                            <Text style={styles.boxTitle}>Időszakos lekérdezés</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <MaterialIcons name="attach-money" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Pénz be</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={1} style={styles.box}>
                            <View style={styles.boxInner}>
                                <MaterialIcons name="money-off" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Pénz ki</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box}>
                            <View style={styles.boxInner}>
                                <MaterialCommunityIcons name="bottle-soda-classic-outline" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Göngyöleg</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>}
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Teendők
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("PendingOrdersScreen")}>
                            <View style={styles.boxInner}>
                                <MaterialIcons name="dynamic-feed" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Előrendelések</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box}>
                            <View style={styles.boxInner}>
                                <FontAwesome name="list-ul" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Teendők</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => navigation.navigate("TablesBookingsScreen")}>
                            <FontAwesome name="calendar-check-o" size={30} color="#000" style={{marginRight: 15}}/>
                            <Text style={styles.boxTitle}>Foglalások</Text>
                        </TouchableOpacity>
                    </View>
                </View>
                <View style={{width: "100%", marginTop: 20, paddingHorizontal: 15}}>
                    <Text style={{fontWeight: "bold", textAlign: "left", fontSize: 17}}>
                        Raktár műveletek
                    </Text>
                    <View style={{flexDirection: "row", justifyContent: "space-between", marginTop: 15}}>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => {
                            Alert.alert("Figyelem!", "A funkció jelenleg fejlesztés alatt áll, kérjük használja a webes felületet a raktár műveletek elvégzéséhez!", [{text: "Rendben"}]);
                        }}>
                            <View style={styles.boxInner}>
                                <Icon name="book" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Standolás</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => {
                            Alert.alert("Figyelem!", "A funkció jelenleg fejlesztés alatt áll, kérjük használja a webes felületet a raktár műveletek elvégzéséhez!", [{text: "Rendben"}]);
                        }}>
                            <View style={styles.boxInner}>
                                <FontAwesome5 name="file-invoice" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Bevételezés</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity activeOpacity={0.7} style={styles.box} onPress={() => {
                            Alert.alert("Figyelem!", "A funkció jelenleg fejlesztés alatt áll, kérjük használja a webes felületet a raktár műveletek elvégzéséhez!", [{text: "Rendben"}]);
                        }}>
                            <View style={styles.boxInner}>
                                <MaterialCommunityIcons name="chart-bell-curve" size={30} color="#000" style={{marginRight: 15}}/>
                                <Text style={styles.boxTitle}>Fogyó készlet</Text>
                            </View>
                        </TouchableOpacity>
                    </View>
                </View>
                </View>
            </ScrollView>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 20,
    },
    subtitle: {
        fontSize: 16,
        marginBottom: 40,
    },
    boxInner: {
        alignItems: "center",
        justifyContent: "center",
        flexDirection: 'row',
    },
    box: {
        flexDirection: 'row',
        width: "30%",
        padding: 10,
        backgroundColor: "#fff",
        borderRadius: 10,
        alignItems: "center",
        justifyContent: "center",
        shadowColor: "#000",
        shadowOffset: {width: 0, height: 2},
        shadowOpacity: 0.2,
        shadowRadius: 2,
        elevation: 3,
    },
    boxTitle: {
        marginTop: 0,
        fontSize: 14,
        fontWeight: "bold",
        textAlign: "center",
    }
});

export default FunctionsScreen;
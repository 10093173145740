import React, { useState, useContext } from 'react';
import { View, TextInput, Button, StyleSheet, TouchableOpacity, ScrollView, Text, Platform } from 'react-native';
import AddressInput from '../components/AddressInput';
import DateTimePicker from '@react-native-community/datetimepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { OnlineOrderContext } from '../helpers/OnlineOrderContext';
import GuestList from '../components/GuestList';
import Ionicons from 'react-native-vector-icons/Ionicons';
import DateTimePickerButton from "../components/DateTimePickerButton";

const OnlineContainer = ({ setDateTime, showPicker, dateTime, setDateTimeHandler, handleSubmit, formData, setFormData, formErrors, webDateChange, toggleNotes, showNotes }) => (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.formContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Vezetéknév</Text>
                    <TextInput
                        style={[styles.input, formErrors.lastName ? styles.inputError : null]}
                        placeholder="Vezetéknév"
                        value={formData.lastName}
                        onChangeText={(text) => setFormData({ ...formData, lastName: text })}
                    />
                    {formErrors.lastName && <Text style={styles.errorText}>{formErrors.lastName}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Keresztnév</Text>
                    <TextInput
                        style={[styles.input, formErrors.firstName ? styles.inputError : null]}
                        placeholder="Keresztnév"
                        value={formData.firstName}
                        onChangeText={(text) => setFormData({ ...formData, firstName: text })}
                    />
                    {formErrors.firstName && <Text style={styles.errorText}>{formErrors.firstName}</Text>}
                </View>
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Telefonszám</Text>
                <TextInput
                    style={[styles.input, formErrors.phoneNumber ? styles.inputError : null]}
                    placeholder="Telefonszám"
                    keyboardType="phone-pad"
                    value={formData.phoneNumber}
                    onChangeText={(text) => setFormData({ ...formData, phoneNumber: text })}
                />
                {formErrors.phoneNumber && <Text style={styles.errorText}>{formErrors.phoneNumber}</Text>}
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Cím kereső</Text>
                <AddressInput
                    setAddress={(address) => setFormData({ ...formData, ...address })}
                />
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Irányítószám</Text>
                    <TextInput
                        style={[styles.input, formErrors.zip ? styles.inputError : null]}
                        placeholder="Irányítószám"
                        value={formData.zip}
                        onChangeText={(text) => setFormData({ ...formData, zip: text })}
                    />
                    {formErrors.zip && <Text style={styles.errorText}>{formErrors.zip}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Város</Text>
                    <TextInput
                        style={[styles.input, formErrors.city ? styles.inputError : null]}
                        placeholder="Város"
                        value={formData.city}
                        onChangeText={(text) => setFormData({ ...formData, city: text })}
                    />
                    {formErrors.city && <Text style={styles.errorText}>{formErrors.city}</Text>}
                </View>
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Cím</Text>
                <TextInput
                    style={[styles.input, formErrors.street ? styles.inputError : null]}
                    placeholder="Cím"
                    value={formData.street}
                    onChangeText={(text) => setFormData({ ...formData, street: text })}
                />
                {formErrors.street && <Text style={styles.errorText}>{formErrors.street}</Text>}
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Emelet, ajtó, kapucsengő</Text>
                <TextInput
                    style={styles.input}
                    placeholder="Emelet, ajtó, kapucsengő"
                    value={formData.additionalAddressDetails}
                    onChangeText={(text) => setFormData({ ...formData, additionalAddressDetails: text })}
                />
            </View>
            {showNotes && (
                <>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>Megjegyzés a futár számára</Text>
                        <TextInput
                            style={[styles.input, { paddingTop: 5 }]}
                            placeholder="Megjegyzés a futár számára"
                            multiline
                            value={formData.note}
                            onChangeText={(text) => setFormData({ ...formData, note: text })}
                        />
                    </View>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>Megjegyzés a konyha számára</Text>
                        <TextInput
                            style={[styles.input, { paddingTop: 5 }]}
                            placeholder="Megjegyzés a konyha számára"
                            multiline
                            value={formData.kitchenNote}
                            onChangeText={(text) => setFormData({ ...formData, kitchenNote: text })}
                        />
                    </View>
                </>
            )}
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <DateTimePickerButton onDateChange={(date) => webDateChange(date)} />
                <TouchableOpacity style={styles.toggleComment} onPress={toggleNotes}>
                    <Ionicons
                        name={showNotes ? "checkbox-outline" : "square-outline"}
                        size={22}
                        color="white"
                    />
                    <Text style={styles.toggleCommentText}> Megjegyzések</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.orderButton} onPress={handleSubmit}>
                    <Text style={styles.orderButtonText}>Rendelés létrehozása</Text>
                </TouchableOpacity>
            </View>
            {showPicker && (
                Platform.OS === 'web' ? (
                    <></>
                ) : (
                    <DateTimePicker
                        value={dateTime}
                        mode="datetime"
                        display="default"
                        onChange={setDateTimeHandler}
                    />
                )
            )}
        </View>
    </ScrollView>
);

const TakeAwayContainer = ({ setDateTime, showPicker, dateTime, setDateTimeHandler, handleSubmit, formData, setFormData, formErrors,webDateChange,toggleNotes, showNotes }) => (
    <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.formContainer}>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Vezetéknév</Text>
                    <TextInput
                        style={[styles.input, formErrors.lastName ? styles.inputError : null]}
                        placeholder="Vezetéknév"
                        value={formData.lastName}
                        onChangeText={(text) => setFormData({ ...formData, lastName: text })}
                    />
                    {formErrors.lastName && <Text style={styles.errorText}>{formErrors.lastName}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Keresztnév</Text>
                    <TextInput
                        style={[styles.input, formErrors.firstName ? styles.inputError : null]}
                        placeholder="Keresztnév"
                        value={formData.firstName}
                        onChangeText={(text) => setFormData({ ...formData, firstName: text })}
                    />
                    {formErrors.firstName && <Text style={styles.errorText}>{formErrors.firstName}</Text>}
                </View>
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Telefonszám</Text>
                <TextInput
                    style={[styles.input, formErrors.phoneNumber ? styles.inputError : null]}
                    placeholder="Telefonszám"
                    keyboardType="phone-pad"
                    value={formData.phoneNumber}
                    onChangeText={(text) => setFormData({ ...formData, phoneNumber: text })}
                />
                {formErrors.phoneNumber && <Text style={styles.errorText}>{formErrors.phoneNumber}</Text>}
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Irányítószám</Text>
                    <TextInput
                        style={[styles.input, formErrors.zip ? styles.inputError : null]}
                        placeholder="Irányítószám"
                        value={formData.zip}
                        onChangeText={(text) => setFormData({ ...formData, zip: text })}
                    />
                    {formErrors.zip && <Text style={styles.errorText}>{formErrors.zip}</Text>}
                </View>
                <View style={styles.inputContainer}>
                    <Text style={styles.label}>Város</Text>
                    <TextInput
                        style={[styles.input, formErrors.city ? styles.inputError : null]}
                        placeholder="Város"
                        value={formData.city}
                        onChangeText={(text) => setFormData({ ...formData, city: text })}
                    />
                    {formErrors.city && <Text style={styles.errorText}>{formErrors.city}</Text>}
                </View>
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Cím</Text>
                <TextInput
                    style={[styles.input, formErrors.street ? styles.inputError : null]}
                    placeholder="Cím"
                    value={formData.street}
                    onChangeText={(text) => setFormData({ ...formData, street: text })}
                />
                {formErrors.street && <Text style={styles.errorText}>{formErrors.street}</Text>}
            </View>
            <View style={styles.inputContainer}>
                <Text style={styles.label}>Emelet, ajtó, kapucsengő</Text>
                <TextInput
                    style={styles.input}
                    placeholder="Emelet, ajtó, kapucsengő"
                    value={formData.additionalAddressDetails}
                    onChangeText={(text) => setFormData({ ...formData, additionalAddressDetails: text })}
                />
            </View>
            {showNotes && (
                <>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>Megjegyzés a futár számára</Text>
                        <TextInput
                            style={[styles.input, { paddingTop: 5 }]}
                            placeholder="Megjegyzés a futár számára"
                            multiline
                            value={formData.note}
                            onChangeText={(text) => setFormData({ ...formData, note: text })}
                        />
                    </View>
                    <View style={styles.inputContainer}>
                        <Text style={styles.label}>Megjegyzés a konyha számára</Text>
                        <TextInput
                            style={[styles.input, { paddingTop: 5 }]}
                            placeholder="Megjegyzés a konyha számára"
                            multiline
                            value={formData.kitchenNote}
                            onChangeText={(text) => setFormData({ ...formData, kitchenNote: text })}
                        />
                    </View>
                </>
            )}
            <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                <DateTimePickerButton onDateChange={(date) => webDateChange(date)} />
                <TouchableOpacity style={styles.toggleComment} onPress={toggleNotes}>
                    <Ionicons
                        name={showNotes ? "checkbox-outline" : "square-outline"}
                        size={22}
                        color="white"
                    />
                    <Text style={styles.toggleCommentText}> Megjegyzések</Text>
                </TouchableOpacity>

                <TouchableOpacity style={styles.orderButton} onPress={handleSubmit}>
                    <Text style={styles.orderButtonText}>Rendelés létrehozása</Text>
                </TouchableOpacity>
            </View>
            {showPicker && (
                Platform.OS === 'web' ? (
                    <></>
                ) : (
                    <DateTimePicker
                        value={dateTime}
                        mode="datetime"
                        display="default"
                        onChange={setDateTimeHandler}
                    />
                )
            )}
        </View>
    </ScrollView>
);

const OnlineNewOrder = ({ navigation }) => {
    const { updateOrderData } = useContext(OnlineOrderContext);
    const [selectedForm, setSelectedForm] = useState('online');
    const [dateTime, setDateTime] = useState(new Date());
    const [showPicker, setShowPicker] = useState(false);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phoneNumber: '',
        zip: '',
        city: '',
        street: '',
        additionalAddressDetails: '',
        note: '',
        kitchenNote: '',
    });
    const [isGuestListCollapsed, setIsGuestListCollapsed] = useState(true);
    const [formErrors, setFormErrors] = useState({});
    const [showNotes, setShowNotes] = useState(false);

    const toggleNotes = () => {
        setShowNotes(!showNotes);
    };
    const validateForm = () => {
        const errors = {};

        // Required fields check
        if (!formData.firstName.trim()) errors.firstName = "Keresztnév megadása kötelező";
        if (!formData.lastName.trim()) errors.lastName = "Vezetéknév megadása kötelező";
        if (!formData.phoneNumber.trim()) {
            errors.phoneNumber = "Telefonszám megadása kötelező";
        } else if (!/^\+?[0-9]{1,15}$/.test(formData.phoneNumber)) {
            errors.phoneNumber = "Érvénytelen telefonszám (csak számok vagy '+' az elején)";
        }
        if (formData.phoneNumber.length > 16) {
            errors.phoneNumber = "A telefonszám nem lehet hosszabb, mint 16 karakter";
        }
        if (!formData.zip.trim()) errors.zip = "Irányítószám megadása kötelező";
        if (!formData.city.trim()) errors.city = "Város megadása kötelező";
        if (!formData.street.trim()) errors.street = "Cím megadása kötelező";

        // Email validation (optional if included in the form)
        if (formData.email && !/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formData.email)) {
            errors.email = "Érvénytelen email cím";
        }

        return errors;
    };

    const handleGuestSelect = (guest) => {
        setFormData({
            firstName: guest.userInfo?.name?.first || '',
            lastName: guest.userInfo?.name?.last || '',
            phoneNumber: guest.userInfo?.phone || '',
            zip: guest.addressInfo?.zip || '',
            city: guest.addressInfo?.city || '',
            street: guest.addressInfo?.address || '',
            additionalAddressDetails: formData.additionalAddressDetails,
            note: formData.note,
            kitchenNote: formData.kitchenNote,
        });
    };

    const setDateTimeHandler = (event, selectedDate) => {
        const currentDate = selectedDate || dateTime;
        setShowPicker(false);
        setDateTime(currentDate);
    };
    const webDateChange = (date) => {
        setFormData({ ...formData, preOrder: date });
        console.log('Selected date:', date);
    };

    const handleSubmit = () => {
        const errors = validateForm();
        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            return;
        }

        setFormErrors({}); // Clear errors if valid

        const userInfo = {
            name: {
                first: formData.firstName,
                last: formData.lastName,
            },
            email: formData.email || '',
            phone: formData.phoneNumber,
        };

        const addressInfo = {
            zip: formData.zip,
            city: formData.city,
            address: formData.street,
            additionalAddressDetails: formData.additionalAddressDetails,
            coordinates: [47.8354562, 22.6825662], // Example coordinates
            coordinates_raw: { lat: 47.8354562, lng: 22.6825662 }, // Example coordinates
            deliveryInstructions: showNotes ? formData.note || '' : '',
            other: '',
        };

        const orderData = {
            ...formData,
            dateTime: dateTime,
            userInfo: userInfo,
            addressInfo: addressInfo,
            kitchenNote: showNotes ? formData.kitchenNote || '' : '',
            preOrder: formData.preOrder || '',
        };

        console.log("orderData::", orderData);
        updateOrderData(orderData, selectedForm);
        navigation.navigate('HomeScreen', { navigateToSale: true });
    };

    return (
        <View style={{ flex: 1 }}>
            <View style={styles.buttonContainer}>
                <TouchableOpacity
                    style={[styles.tabButton, selectedForm === 'online' && styles.activeTabButton]}
                    onPress={() => setSelectedForm('online')}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                        <Ionicons name="bicycle-outline" size={24} style={{ marginRight: 8 }} />
                        <Text style={styles.tabButtonText}>Házhozszállítás</Text>
                    </View>
                </TouchableOpacity>
                <TouchableOpacity
                    style={[styles.tabButton, selectedForm === 'takeaway' && styles.activeTabButton]}
                    onPress={() => setSelectedForm('takeaway')}
                >
                    <View style={{ flexDirection: 'row', alignItems: 'center', width: '100%', justifyContent: 'center' }}>
                        <Ionicons name="walk-outline" size={24} style={{ marginRight: 8 }} />
                        <Text style={styles.tabButtonText}>Elvitelre</Text>
                    </View>
                </TouchableOpacity>
            </View>
            <View style={{ flex: 1 }}>
                {selectedForm === 'online' ? (
                    <OnlineContainer
                        setDateTime={() => setShowPicker(true)}
                        showPicker={showPicker}
                        dateTime={dateTime}
                        setDateTimeHandler={setDateTimeHandler}
                        handleSubmit={handleSubmit}
                        formData={formData}
                        setFormData={setFormData}
                        formErrors={formErrors}
                        webDateChange={webDateChange}
                        toggleNotes={toggleNotes}
                        showNotes={showNotes}
                    />
                ) : (
                    <TakeAwayContainer
                        setDateTime={() => setShowPicker(true)}
                        showPicker={showPicker}
                        dateTime={dateTime}
                        setDateTimeHandler={setDateTimeHandler}
                        handleSubmit={handleSubmit}
                        formData={formData}
                        setFormData={setFormData}
                        formErrors={formErrors}
                        webDateChange={webDateChange}
                        toggleNotes={toggleNotes}
                        showNotes={showNotes}
                    />
                )}
            </View>
            <TouchableOpacity
                style={styles.toggleButton}
                onPress={() => setIsGuestListCollapsed(!isGuestListCollapsed)}
            >
                <Text style={styles.toggleButtonText}>
                    {!isGuestListCollapsed ? <Ionicons name="chevron-down-outline" size={24} /> : <Ionicons name="chevron-up-outline" size={24} />}
                </Text>
            </TouchableOpacity>
            <View style={[isGuestListCollapsed ? styles.collapsed : styles.guestListContainer]}>
                <GuestList filterCriteria={formData} onGuestSelect={handleGuestSelect} />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    formContainer: {
        padding: 20,
        flex: 1,
    },
    toggleComment: {
        backgroundColor: '#50A5F1',
        padding: 10,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: "row",
        shadowColor: '#000', // Added shadow
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        elevation: 4,
    },
    toggleCommentText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },
    input: {
        minHeight: 50,
        borderColor: 'gray',
        borderWidth: 1,
        paddingHorizontal: 15,
        borderRadius: 8,
        fontSize: 16,
    },
    inputContainer: {
        marginBottom: 10,
        flex :1,
    },
    label: {
        fontSize: 14,
        marginBottom: 2,
    },
    orderButton: {
        backgroundColor: '#f76e24',
        paddingVertical: 10,
        paddingHorizontal: 20,
        borderRadius: 8,
        alignItems: 'center',
        justifyContent: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 6,
        elevation: 4,
    },
    errorText: {
        color: 'red',
        fontSize: 12,
        marginTop: 4,
    },

    inputError: {
        borderColor: 'red',
        borderWidth: 1,
    },
    orderButtonText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 16,
    },

    buttonContainer: {
        flexDirection: 'row',
        backgroundColor: '#f0f0f0',
        paddingVertical: 10,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tabButton: {
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: '50%',
        alignItems: 'center',
    },
    activeTabButton: {
        borderBottomWidth: 4,
        borderBottomColor: '#f76e24',
    },
    tabButtonText: {
        fontSize: 16,
        fontWeight: 'bold',
        width: '50%',
        alignItems: 'center',
        textAlign: 'center'
    },
    floatLeftButton: {
        alignSelf: 'flex-end',
        width: '250px',
        padding: 8
    },
    guestListContainer: {
        flex: 2.75,
        paddingBottom: 15,
    },
    collapsed: {
        flex: 0.2,
        paddingBottom: 15,

    },
    scrollContainer: {
        flexGrow: 1,
    },
    toggleButton: {
        alignItems: 'center',
        padding: 5,
        backgroundColor: '#dadada',
    },
    toggleButtonText: {
        fontSize: 18,
    },
});

export default OnlineNewOrder;
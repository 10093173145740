import React, { useEffect, useState, useContext, useRef } from 'react';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Animated } from 'react-native';
import Map from 'react-map-gl';
import 'mapbox-gl/dist/mapbox-gl.css'; // Import the Mapbox CSS
import AsyncStorage from "@react-native-async-storage/async-storage";
import { OrdersContext } from "../helpers/OrdersContext";
import { priceFormatter } from "../helpers/numbers";
import { Ionicons } from "@expo/vector-icons";
import foodpandaIcon from "../../assets/foodpanda-icon.png";
import woltIcon from "../../assets/wolt.png";
import { SystemContext } from "../helpers/SystemContext";
import { Menu } from "react-native-paper";
import StatisticsHelper from "../helpers/StatisticsHelper";
import { Swipeable, TapGestureHandler, State } from 'react-native-gesture-handler';
import { getAccessToken } from "../helpers/storage";
import HappyAlert from '../helpers/crossPlatformTools/HappyAlert';

const MAPBOX_TOKEN = 'pk.eyJ1IjoiZ29vZG8iLCJhIjoiY2t2eHg5cWhuNDM3YjJwa2xxejVubnhhcCJ9.py506Q9y-4k30wIs6xh2Ew';

const OnlineMapScreen = ({ navigation }) => {
    const swipeableRefs = useRef([]);
    const [viewport, setViewport] = useState({
        latitude: 47.5025613999349,
        longitude: 19.05690176184082,
        zoom: 10,
        bearing: 0,
        pitch: 0,
        width: '100%',
        height: '100%'
    });
    const { restaurantPlace, setRestaurantPlace } = useContext(SystemContext);
    const { orders, fetchOrdersFromAPI } = useContext(OrdersContext);
    const { productsSum, statistics } = StatisticsHelper(orders);
    const [expandedOrder, setExpandedOrder] = useState(null);
    const [menuVisible, setMenuVisible] = useState(false);
    const [deliveryMenuVisible, setDeliveryMenuVisible] = useState(false);
    const [selectedOrders, setSelectedOrders] = useState([]);
    const [alertVisible, setAlertVisible] = useState(false); // State for alert visibility
    const [alertMessage, setAlertMessage] = useState(''); // State for alert message
    const [alertType, setAlertType] = useState('info'); // State for alert type
    const [selectedCurrier, setSelectedCurrier] = useState(null);
    const [confirmAction, setConfirmAction] = useState(undefined);


    const getNextStatus = (currentStatus) => {
        switch (currentStatus) {
            case 'pending':
                return { nextStatus: 'accepted', buttonText: 'Elfogadás', buttonColor: '#f76e24' };
            case 'accepted':
                return { nextStatus: 'preparing', buttonText: 'Készül', buttonColor: 'rgba(248,192,98,0.85)' };
            case 'preparing':
                return { nextStatus: 'busy', buttonText: 'Kész', buttonColor: "#28a745" };
            case 'busy':
                return { nextStatus: 'closed', buttonText: 'Lezárás', buttonColor: "#FFA500" };
            default:
                return { nextStatus: 'pending', buttonText: 'Elfogadás' };
        }
    };

    const StatusUpdateButton = ({ order }) => {
        const { nextStatus, buttonText } = getNextStatus(order.status);

        return (
            <TouchableOpacity
                onPress={() => confirmAndUpdateOrderStatus(order._id, nextStatus)}
                style={[styles.detailsButton, styles.statusUpdateButton, { flex: 1, backgroundColor: getNextStatus(order.status).buttonColor }]}
            >
                <Text style={styles.detailsButtonText}>{buttonText}</Text>
            </TouchableOpacity>
        );
    };

    const confirmAndUpdateOrderStatus = (orderId, status) => {
        if (status === "closed") {
            setAlertType('warning');
            setAlertMessage('Biztosan le szeretnéd zárni a rendelést?');
            setAlertVisible(true);
            setConfirmAction(() => async () => {
                await updateOrderStatus(orderId, status);
                setAlertVisible(false);
            });
        } else {
            updateOrderStatus(orderId, status);
        }
    };

    const updateOrderStatus = async (orderId = "", status) => {
        try {
            const token = await getAccessToken();
            const response = await fetch(`https://api.happygastro.hu/api/order/${orderId}/status/${status}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Replace with your actual token
                }
            });
            if (!response.ok) {
                throw new Error('Failed to update order status');
            }
            await response.json();
            await fetchOrdersFromAPI();
        } catch (err) {
            console.log("Error while updating order status:", err);
        }
    };

    const startSelectedOrders = async (selectedOrders) => {
        if (selectedOrders.length === 0) {
            console.log("No orders selected to start.");
            return;
        }

        try {
            const token = await getAccessToken();
            const response = await fetch("https://api.happygastro.hu/api/restaurant/route/start", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    objectIds: selectedOrders
                })
            });

            if (!response.ok) {
                throw new Error("Failed to start selected orders");
            }

            const result = await response.json();
            console.log("Orders successfully started:", result);
            setAlertType('info');
            setAlertMessage('Rendelés(ek) sikeresen indítva.');
            setAlertVisible(true);
            setSelectedCurrier(null);
        } catch (err) {
            console.error("Hiba a rendelés küldése közben", err);
            setAlertType('error');
            setAlertMessage('Nem sikerült elindítani a rendeléseket.');
            setAlertVisible(true);
        }
    };
    const finishSelectedOrders = async (selectedOrders, orders) => {
        const sentOrders = selectedOrders.filter(orderId => {
            const order = orders?.find(o => o._id === orderId) || [];
            return order?.delivery?.sent === true;
        });

        if (sentOrders.length === 0) {
            console.log("No sent orders selected to finish.");
            setAlertType('warning');
            setAlertMessage('Csak az elküldött rendeléseket lehet érkeztetni.');
            setAlertVisible(true);
            return;
        }

        try {
            const token = await getAccessToken();
            const response = await fetch("https://api.happygastro.hu/api/restaurant/route/finish", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    objectIds: sentOrders
                })
            });

            if (!response.ok) {
                throw new Error("Failed to finish selected orders");
            }

            const result = await response.json();
            console.log("Orders successfully finished:", result);

            await fetchOrdersFromAPI();
            setAlertType('success');
            setAlertMessage('Rendelés(ek) sikeresen érkeztetve.');
            setAlertVisible(true);
            setSelectedCurrier(null);
        } catch (err) {
            console.error("Hiba a rendelés érkeztetése közben", err);
            setAlertType('error');
            setAlertMessage('Nem sikerült érkeztetni a rendeléseket.');
            setAlertVisible(true);
        }
    };

    const OrderFromIcon = (orderData) => {
        if (orderData.orderFrom === "netpincer") {
            return <img src={foodpandaIcon} width={50} alt={"foodpanda"} />;
        }
        if (orderData.orderFrom === "wolt") {
            return <img src={woltIcon} width={50} alt={"wolt"} />;
        }
        if (orderData.orderFrom === "webshop") {
            return <Ionicons name="earth-outline" size={24} color="black" />;
        }
        return <Ionicons name="earth-outline" size={24} color="black" />;
    };

    const handleSingleTap = (orderId) => {
        toggleOrderDetails(orderId);
    };

    function convertType(type) {
        switch (type) {
            case "creditCard":
                return "Bankkártya";
            case "cash":
                return "Készpénz";
            case "voucher":
                return "utalvány";
        }
    }

    useEffect(() => {
        console.log("orders:", orders);
        console.log("restaurantPlace:", restaurantPlace);
    }, [navigation]);

    const toggleOrderDetails = (orderId) => {
        setExpandedOrder(expandedOrder === orderId ? null : orderId);
    };

    const handleSwipeLeft = (orderId) => {
        setSelectedOrders(prevSelectedOrders =>
            prevSelectedOrders.includes(orderId)
                ? prevSelectedOrders.filter(id => id !== orderId)
                : [...prevSelectedOrders, orderId]
        );
        console.log(selectedOrders)
    };

    const renderRightActions = (orderId) => (
        <Animated.View style={styles.swipeButton}>
            {/*<Ionicons name="chevron-forward" size={24} color="black" />*/}
        </Animated.View>
    );

    const renderLeftActions = (orderId) => (
        <Animated.View style={styles.swipeButton}>
            {/*<Ionicons name="chevron-back" size={24} color="black" />*/}
        </Animated.View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.leftColumn}>
                <Map
                    mapboxAccessToken={MAPBOX_TOKEN}
                    initialViewState={viewport}
                    mapStyle="mapbox://styles/mapbox/standard"
                />
            </View>
            <View style={styles.rightColumn}>
                <View style={styles.buttonContainer}>
                    <Menu
                        visible={deliveryMenuVisible}
                        onDismiss={() => setDeliveryMenuVisible(false)}
                        anchor={
                            <TouchableOpacity activeOpacity={0.7} style={styles.tabButton} onPress={() => setDeliveryMenuVisible(true)}>
                                <Ionicons name="car" size={24} color="black" />
                                <Text style={styles.tabButtonText}>Futárok</Text>
                            </TouchableOpacity>
                        }
                    >
                        <Menu.Item style={selectedCurrier === "Futár Jóska" ? { backgroundColor: '#c9ffa6' } : {}} onPress={() => {
                            setSelectedCurrier("Futár Jóska");
                            setDeliveryMenuVisible(false)
                        }} title="Futár Jóska" />
                        <Menu.Item style={selectedCurrier === "Késő János" ? { backgroundColor: '#c9ffa6' } : {}} onPress={() => {
                            setSelectedCurrier("Késő János");
                            setDeliveryMenuVisible(false)
                        }} title="Késő János" />
                        <Menu.Item style={selectedCurrier === "Beugrós futár" ? { backgroundColor: '#c9ffa6' } : {}} onPress={() => {
                            setSelectedCurrier("Beugrós futár");
                            setDeliveryMenuVisible(false)
                        }} title="Beugrós futár" />
                        <Menu.Item style={selectedCurrier === "Wolt delivery" ? { backgroundColor: '#c9ffa6' } : {}} onPress={() => {
                            setSelectedCurrier("Wolt delivery");
                            setDeliveryMenuVisible(false)
                        }} title="Wolt delivery" />
                    </Menu>
                    <TouchableOpacity activeOpacity={0.7} style={styles.tabButton} onPress={() => startSelectedOrders(selectedOrders)}>
                        <Ionicons name="play" size={24} color="#f1b44c" style={{ color: '#c08b31' }} />
                        <Text style={[styles.tabButtonText, { color: '#c08b31' }]}>Indítás</Text>
                    </TouchableOpacity>
                    <TouchableOpacity activeOpacity={0.7} style={styles.tabButton} onPress={() => finishSelectedOrders(selectedOrders, statistics.onlineOrders
                        .filter((o) => o.status !== "closed" && !o.storno)
                        .concat(statistics.unClosedTakeAwayOrders.filter((o) => o.status !== "closed" && !o.storno))
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)))}>
                        <Ionicons name="stop" size={24} color="#1f946a" />
                        <Text style={[styles.tabButtonText, { color: '#1f946a' }]}>Érkeztetés</Text>
                    </TouchableOpacity>
                </View>
                <Text style={styles.restaurantName}>{restaurantPlace.raw}</Text>
                <ScrollView>

                    {statistics.onlineOrders
                        .filter((o) => o.status !== "closed" && !o.storno)
                        .concat(statistics.unClosedTakeAwayOrders.filter((o) => o.status !== "closed" && !o.storno))
                        .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)) // Sort by descending date
                        .map((order, index) => (
                            <Swipeable
                                key={order._id}
                                ref={ref => swipeableRefs.current[index] = ref}
                                renderRightActions={() => renderRightActions(order._id)}
                                renderLeftActions={() => renderLeftActions(order._id)}
                                onSwipeableWillOpen={(direction) => {
                                    if (direction === 'left') {
                                        toggleOrderDetails(order._id);
                                    } else if (direction === 'right') {
                                        handleSwipeLeft(order._id);
                                    }
                                    swipeableRefs.current[index]?.close();
                                }}
                                style={[
                                    styles.orderContainer,
                                    expandedOrder === order._id && styles.orderSummaryExpanded,
                                    selectedOrders.includes(order._id) && styles.selectedOrder,
                                    order.preOrder && styles.preOrderBackground, // Apply the preOrder background style// Apply the sent order background style
                                ]}
                            >
                                <TapGestureHandler
                                    onHandlerStateChange={({ nativeEvent }) => {
                                        if (nativeEvent.state === State.ACTIVE) {
                                            toggleOrderDetails(order._id);
                                        }
                                    }}
                                >
                                    <View style={[
                                        styles.orderContainer,
                                        expandedOrder === order._id && styles.orderSummaryExpanded,
                                        selectedOrders.includes(order._id) && styles.selectedOrder,
                                        (!selectedOrders.includes(order._id) && order.delivery?.sent) && styles.sentBg,
                                    ]}>
                                        <View style={[styles.orderSummary]}>
                                            <View style={{ alignItems: 'center', justifyContent: 'center', flexDirection: "column" }}>
                                                <Text style={styles.orderTime}>{new Date(order.createdAt).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</Text>
                                                <OrderFromIcon orderData={order} />
                                            </View>
                                            <View>
                                                <Text style={[styles.orderCode, { textAlign: 'center' }]}>#{order.orderCode}</Text>
                                                {order.preOrder && (
                                                    <Text style={[styles.orderDate, { textAlign: 'center' }]}>{new Date(order.preOrder).toLocaleString()}</Text>
                                                )}
                                            </View>
                                            <View style={{ flexDirection: "column" }}>
                                                <Text style={styles.orderPrice}>{priceFormatter(order.orderSum)} </Text>
                                                <Text style={styles.itemCount}>{order.items.length} tétel(ek)</Text>
                                                <Text style={styles.orderPrice}>{convertType(order.paymentType)}</Text>
                                            </View>
                                        </View>
                                        {expandedOrder === order._id && (
                                            <View style={[styles.orderDetails]}>
                                                {order.items.map((item, index) => {
                                                    const selectedSize = item.item.sizes.find(size => size._id === item.size);
                                                    return (
                                                        <View key={index} style={{ flexDirection: 'row', justifyContent: 'space-between', marginBottom: 4 }}>
                                                            <Text style={[styles.itemText, { fontWeight: "bold" }]}>{item.amount}x</Text>
                                                            <Text style={styles.itemText}>{item.name} ({selectedSize.unit})</Text>
                                                            <Text style={[styles.itemText, { fontWeight: "bold" }]}>{priceFormatter(item.remotePrice || item.price)}</Text>
                                                        </View>
                                                    );
                                                })}</View>)}
                                    </View>
                                </TapGestureHandler>
                                {expandedOrder === order._id && (
                                    <View style={{ flexDirection: "row" }}>
                                        <TouchableOpacity
                                            onPress={() => navigation.navigate('OrderDetailsScreen', { orderData: order })}
                                            style={{ flex: 1 }}
                                        >
                                            <Text style={styles.detailsButton}>Részletek</Text>
                                        </TouchableOpacity>
                                        <StatusUpdateButton order={order} />
                                    </View>
                                )}
                            </Swipeable>
                        ))}
                </ScrollView>
            </View>
            <HappyAlert
                visible={alertVisible}
                onConfirm={() => confirmAction() || setAlertVisible(false)}
                onCancel={() => setAlertVisible(false)}
                type={alertType}
                message={alertMessage}
            />
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    leftColumn: {
        width: '70%',
    },
    rightColumn: {
        backgroundColor: '#f0f0f0',
        width: '30%',
        padding: 10,
    },
    itemText: {
        fontSize: 14,
        color: '#333',
        marginBottom: 4,
    },
    restaurantName: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        paddingVertical: 8,
        borderBottomWidth: 0.8,
        borderBottomColor: 'black'
    },
    orderContainer: {
        padding: 0,
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
    },
    orderSummary: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textAlign: 'center',
        paddingVertical: 12,
    },
    orderSummaryExpanded: {
        backgroundColor: '#e0e0e0', // Lighter background color for expanded order
    },
    orderDetails: {
        paddingTop: 0,
    },
    orderDetailsExpanded: {
        backgroundColor: '#e0e0e0', // Lighter background color for expanded order details
    },
    orderTime: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    orderCode: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    preOrderBackground: {
        backgroundColor: '#ffe5b4', // Light orange background
    },
    sentBg: {
        backgroundColor: 'rgba(84,209,255,0.81)', // Light orange background
    },
    orderDate: {
        fontSize: 12,
        color: '#888', // Grey color
    },
    orderPrice: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    itemCount: {
        fontSize: 16,
        color: '#616161',
        textAlign: 'left'
    },
    detailsButton: {
        color: '#fff',
        backgroundColor: '#f76e24',
        paddingVertical: 8,
        paddingHorizontal: 16,
        borderRadius: 5,
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: 5,
    },
    icon: {
        color: '#6e6e6e',
    },
    statusUpdateButton: {
        marginLeft: 10,             // Add spacing between buttons
    },
    detailsButtonText: {
        color: '#fff',
        fontWeight: 'bold',
        textAlign: "center"
    },
    menu: {
        width: 240,
        position: 'absolute',
        top: 0,
        zIndex: 67234,
        backgroundColor: '#fff',
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
        paddingVertical: 10,
        backgroundColor: '#e0e0e0',
    },
    tabButton: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    tabButtonText: {
        marginTop: 5,
        fontSize: 14,
        color: '#333',
    },
    swipeButton: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 80,
        backgroundColor: 'transparent',
    },
    selectedOrder: {
        backgroundColor: '#d1e7dd', // Light green background for selected orders
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    }
});

export default OnlineMapScreen;
export const getOrderType = (orderType = "takeAway") => {
    switch (orderType) {
        case "takeAway":
            return "Elvitel";
        case "online":
            return "Házhozszállítás";
        case "dineIn":
            return "Helyben fogyasztás";
        default:
            return "Ismeretlen";
    }
}

export const getOrderPlatform = (orderFrom = "") => {
    switch (orderFrom) {
        case "foodora":
            return "Foodora";
        case "netpincer":
            return "Foodora";
        case "wolt":
            return "Wolt";
        case "webshop":
            return "Webshop";
        case "phone":
            return "Telefon";
        default:
            return "Terminál";
    }
}
import React, { useContext, useEffect, useState } from 'react';
import {
    Alert,
    Dimensions, FlatList,
    Linking, Modal,
    Platform,
    StyleSheet,
    Switch,
    Text,
    TextInput,
    ToastAndroid,
    TouchableOpacity,
    View
} from 'react-native';
import { LinearGradient } from "expo-linear-gradient";
import Feather from 'react-native-vector-icons/Feather';
import Spinner from "react-native-loading-spinner-overlay";
import { priceFormatter } from "../helpers/numbers";
import VivaPaymentModule from "../paymentModules/VivaPaymentsModule";
import { CartContext } from "../helpers/CartContext";
import { BluetoothContext } from "../helpers/BluetoothContext";
import { OrdersContext } from "../helpers/OrdersContext";
import { handlePayment, send_storeOrder } from "../helpers/paymentHelper";
import Icon from "react-native-vector-icons/Feather";
import VatModal from "../components/VatModal";
import {TableContext} from "../helpers/TableContext";
import { hungarianRoundNumbers } from '../helpers/hungarianRoundNumbers';
import PinPad from "../components/PinPad";
import {HappyDialog} from "../helpers/crossPlatformTools/dialogs";
import {VipCardContext} from "../helpers/VipCardContext";

const { width, height } = Dimensions.get('window');

const PaymentScreen = ({ navigation, setIsModalVisible }) => {
    const { cartTotal = 0, setCart, cartItems = [], originalCartTotal, productsPrice, discountsForWholeCart, setCartItems, serviceFeeAmount, cartTotalWithServiceFee, alreadySentCartItems } = useContext(CartContext);
    const { currentTable } = useContext(TableContext);
    const { selectedVipCard } = useContext(VipCardContext);
    const { sendData } = useContext(BluetoothContext);
    const [processing, setProcessing] = useState(false);
    const [needInvoice, setNeedInvoice] = useState(false);
    const [paymentType, setPaymentType] = useState(false);
    const [originalAmount, setOriginalAmount] = useState(cartTotal || 0);
    const [amount, setAmount] = useState(cartTotal || 0);
    const [paidAmount, setPaidAmount] = useState(0);
    const [amountToPay, setAmountToPay] = useState(Math.round(originalAmount));
    const [changeToReturn, setChangeToReturn] = useState(0);
    const [modalVisible, setModalVisible] = useState(false);
    const [vatInvoiceData, setVatInvoiceData] = useState(false);
    const [isSplitOrder, setIsSplitOrder] = useState(true);
    const [payments, setPayments] = useState([]);
    const [paymentsSum, setPaymentsSum] = useState(0);
    const {orders} = useContext(OrdersContext);
    const [paymentsModal, setPaymentsModal] = useState(false);

    const toggleModal = () => {
        setPaymentsModal(!paymentsModal);
    };
    useEffect(() => {
        setPaymentsSum(payments.reduce((acc, payment) => acc + payment.amount, 0))
    },[payments])
    // useEffect(() => {
    //     console.log("cartTotal,",cartTotal)
    //     console.log("originalAmount,",originalAmount)
    //     console.log("cartTotalWithServiceFee,",cartTotalWithServiceFee)
    // },[cartTotal, originalAmount, cartTotalWithServiceFee])

    useEffect(() => {
        if (currentTable){
            if(paymentType !== 'Bankkártya' && paymentType !== 'creditCard') {
                const roundedAmount = Math.round(cartTotalWithServiceFee);
                setOriginalAmount(roundedAmount);
                setAmount(roundedAmount);
            }else{
                setOriginalAmount(cartTotalWithServiceFee);
                setAmount(cartTotalWithServiceFee);
            }
        }
    }, [currentTable, cartTotalWithServiceFee]);

    const getPaymentOptions = () => {
        if (selectedVipCard){
            return [
                { type: 'Készpénz', icon: 'dollar-sign' },
                { type: 'Bankkártya', icon: 'credit-card' },
                { type: 'Utalvány', icon: 'tag' },
                { type: 'VIP Számla', icon: 'star' } , // Add VIP Balance payment type
            ]
        }else{
            return [
                { type: 'Készpénz', icon: 'dollar-sign' },
                { type: 'Bankkártya', icon: 'credit-card' },
                { type: 'Utalvány', icon: 'tag' },
            ]
        }
    }

    const formatAmountWithSpaces = (value) => {
        if (!value) return '';

        // Split number into integer and decimal parts
        const [integerPart, decimalPart] = value.toString().split('.');

        // Add spaces for thousands in the integer part
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

        // Return formatted value with decimal part if it exists
        return decimalPart !== undefined ? `${formattedInteger}.${decimalPart}` : formattedInteger;
    };


    const reverseConvertPaymentType = (paymentType) => {
        if (paymentType === "cash") return "Készpénz";
        if (paymentType === "creditCard") return "Bankkártya";
        if (paymentType === "voucher") return "Utalvány";
        if (paymentType === "vipBalance") return "VIP egyenleg";
        return `${paymentType}`;
    }

    const confirmPaymentAmount = async (isTip) => {
        const totalPaidAmount = isSplitOrder ? paymentsSum : paidAmount;
        const amountToBePaid = originalAmount;
        const surplus = totalPaidAmount - amountToBePaid;
        const tableOrder = orders?.find(
            (order) =>
                !order.storno &&
                order.status !== "cancelled" &&
                order.status !== "closed" &&
                order.tableId === currentTable?._id
        );
        if (totalPaidAmount > amountToBePaid) {
            const tip = (paymentType === 'Bankkártya' || paymentType === 'creditCard') ? surplus : hungarianRoundNumbers(surplus);

            //console.log("surplus:", surplus);
            //console.log("surplus(rounded):", hungarianRoundNumbers(surplus));
            if (isTip) {
                //Todo: test if works as expected
                //Todo: test with creditcard if works as expected
                return handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/}, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, tip, selectedVipCard);
            }else{
                //Todo: test if works as expected
                //Todo: test with creditcard if works as expected
                //this happens when the user clicks on the "Visszajáró" button
                return handlePayment(paymentType, amountToBePaid, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/}, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, 0, selectedVipCard);
            }
        } else {
            console.log("exact");
            return handlePayment(paymentType, paidAmount, amountToBePaid, setProcessing, navigation, cartTotal, setCart, cartItems, sendData, () => {/*onClose*/}, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, false, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments, 0, selectedVipCard);
        }
    };

    useEffect(() => {
        const numericAmount = parseInt(amount) || 0;
        const splitPaymentSum = paymentsSum;
        const roundedOriginalAmount = Math.round(originalAmount);
        const remainingAmount = payments?.length > 0 ? Math.max(roundedOriginalAmount - splitPaymentSum - numericAmount, 0) : Math.max(roundedOriginalAmount - numericAmount, 0);
        const change = payments?.length > 0 ? Math.max(splitPaymentSum - roundedOriginalAmount, 0) : Math.max(numericAmount - roundedOriginalAmount, 0);

        setPaidAmount(numericAmount);
        if(paymentType === 'Készpénz'){
            setAmountToPay(hungarianRoundNumbers(remainingAmount));
        } else {
            setAmountToPay((remainingAmount));
        }

        setChangeToReturn(change);
    }, [amount, originalAmount, payments]);

    const handleSwitchChange = (value) => {
        setNeedInvoice(value);
        if (value) {
            setModalVisible(true);
        }
    };

    const handleModalDismiss = () => {
        setModalVisible(false);
        setNeedInvoice(false);
        setVatInvoiceData(false);
    };
    useEffect(() => {
        if(vatInvoiceData){
            setModalVisible(false)
        }
    },[vatInvoiceData]);

    const handleInput = (value) => {
        setAmount((prev) => ((prev === originalAmount || prev === 0) ? value : prev + value));
    };

    const handleBackspace = () => {
        setAmount((prev) => (prev.length > 1 ? prev.slice(0, -1) : ''));
    };

    const handleClear = () => {
        setAmount(0);
    };
    function canBeClosed(){
        if(isSplitOrder){
            return ((paymentsSum >= (originalAmount - 4)))
        }
        return amountToPay <= 1;
    }

    const convertPaymentType = () => {
        if (paymentType === "Készpénz") return "cash";
        if (paymentType === "Bankkártya") return "creditCard";
        if (paymentType === "Utalvány") return "voucher";
        if (paymentType === "VIP Számla") return "vipBalance";
        return "temporary";
    }

    const handleDeletePayment = (index) => {
        const updatedPayments = [...payments];
        updatedPayments.splice(index, 1);
        setPayments(updatedPayments);
    };


    function addPayment(){
        if (!paymentType) {
            if (Platform.OS === "android") {
                return ToastAndroid.showWithGravity('Kérlek válassz fizetési módot', 5000, ToastAndroid.CENTER);
            } else {
                setProcessing(false);
                return alert('Kérlek válassz fizetési módot');
            }
        }
        setPayments([...payments, {
            "paymentType": convertPaymentType(),
            "amount": Number(amount) || 0,
            "change": 1,
            "currency": "HUF",
        }]);
        console.log("payments",[...payments, {
            "paymentType": convertPaymentType(),
            "amount":  Number(amount) || 0,
            "change": 1,
            "currency": "HUF",
        }])
        setAmount(amountToPay)
        setPaymentType(false)
    }

    const confirmRemovePayments = (onConfirm) => {
        if (Platform.OS === 'web') {
            if (window.confirm("Már vannak kifizetett összegek. Szeretné eltávolítani őket és kikapcsolni a bontott rendelést?")) {
                onConfirm();
            }
        } else {
            Alert.alert(
                "Létező kifizetések",
                "Már vannak kifizetett összegek. Szeretné eltávolítani őket és kikapcsolni a bontott rendelést?",
                [
                    {
                        text: "Mégse",
                        style: "cancel"
                    },
                    {
                        text: "Igen",
                        onPress: onConfirm
                    }
                ],
                { cancelable: false }
            );
        }

    };
    function handleSplitorderToggle() {
        if (isSplitOrder && payments.length > 0) {
            confirmRemovePayments(() => {
                setPayments([]);
                setIsSplitOrder(false);
            });
        } else {
            setIsSplitOrder(!isSplitOrder);
            if (!isSplitOrder) {
                setAmount(originalAmount - paymentsSum);
            }
        }
    }

    const handlePaymentTypeChange = (type) => {
    console.log("type",type)
        setPaymentType(type);
        //second click on a payment type add it to payments IF split order
        if(paymentType === type && isSplitOrder && amount > 0){
            addPayment()
        }else{
            //input amount change based on choose payment type
            if(type === 'Készpénz'){
                if(isSplitOrder){
                    if(paymentsSum < originalAmount && (amount === 0 || !amount)){
                        setAmount(hungarianRoundNumbers((originalAmount - paymentsSum)))
                        console.log("a32mount1",amount)
                        console.log(hungarianRoundNumbers((originalAmount - paymentsSum)))
                    }else{
                        console.log("amount12",amount)
                        setAmount(hungarianRoundNumbers(amount))
                        console.log(hungarianRoundNumbers(amount))
                    }
                }else{
                    console.log("amount1",amount)
                    setAmount(hungarianRoundNumbers(amount))
                    console.log(hungarianRoundNumbers(amount))
                }
            } else if (type === 'Bankkártya') {
                if(cartTotalWithServiceFee && currentTable){
                    //console.log("cartTotalWithServiceFee",cartTotalWithServiceFee)
                    if(!isSplitOrder) {
                        setAmount((cartTotalWithServiceFee))
                    }else{
                        if(paymentsSum < originalAmount && (amount === 0 || !amount)){
                            setAmount(((cartTotalWithServiceFee - paymentsSum)))
                        }else{
                            setAmount((cartTotalWithServiceFee - paymentsSum))
                        }
                    }
                }else{
                    if(!isSplitOrder) {
                        setAmount((originalAmount));
                    }else{
                        if(paymentsSum < originalAmount){
                            setAmount(((originalAmount - paymentsSum)))
                        }
                    }
                }
            }else if (type === 'VIP Számla') {
                setPaymentType('VIP Számla');
                setAmount(((originalAmount - paymentsSum)))
            }else{
                if(!isSplitOrder) {
                    setAmount(originalAmount || 0)
                }else{
                    if(paymentsSum < originalAmount && (amount === 0 || !amount)){
                        setAmount(((originalAmount - paymentsSum)))
                    }
                }
            }
        }
    };

    useEffect(() => {
        return () => {
            setProcessing(false);
        }
    }, []);

        useEffect(() => {
            const callback = async (response) => {
                try {
                    if (response.responseData && response.responseData.status === "success" || response.responseData.status === "SUCCESS") {
                        setCart([]);
                        const tableOrder = orders?.find(
                            (order) =>
                                !order.storno &&
                                order.status !== "cancelled" &&
                                order.status !== "closed" &&
                                order.tableId === currentTable?._id
                        );
                        await handlePayment(paymentType, paidAmount, originalAmount, setProcessing, navigation, currentTable && serviceFeeAmount > 0 ? cartTotalWithServiceFee : cartTotal, setCart, cartItems, sendData, onClose, originalCartTotal, productsPrice, discountsForWholeCart, vatInvoiceData, needInvoice, true, currentTable, serviceFeeAmount, alreadySentCartItems, tableOrder?._id, payments)
                        setIsModalVisible(false)
                        return setProcessing(false);
                    } else {
                        setIsModalVisible(false)
                        setProcessing(false);
                        return navigation.navigate('FailedPayment', {...response.responseData, orderResponse: null});
                    }
                } catch (e) {
                    setIsModalVisible(false)
                    setProcessing(false);
                    console.error("Failed to redirect, error: ", e);
                }
            }

            const handleUrl = async ({url}) => {
                const response = VivaPaymentModule.handleCallbackUrl(url);
                return callback(response)
            };

            const subscription = Linking.addEventListener('url', handleUrl);

            return () => {
                subscription.remove();
            };
        }, []);

    return (
        <View style={[styles.container, { width: '100%' , }]}>
            <LinearGradient
                colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
                style={{ flex: 1, width: '100%', height: '100%' ,}}
            >
                <Spinner
                    visible={processing}
                    textContent={'Feldolgozás...'}
                    textStyle={styles.spinnerTextStyle}
                />
                <View style={styles.tabsContainer}>
                    {getPaymentOptions().map(({ type, icon }) => (
                        <TouchableOpacity
                            key={type}
                            style={[styles.tab, paymentType === type && styles.activeTab]}
                            onPress={() => handlePaymentTypeChange(type)}
                        >
                            <Feather name={icon} size={18} color={paymentType === type ? '#f1b34b' : '#666'} />
                            <Text style={[styles.tabText, paymentType === type && styles.activeTabText]}>{type}</Text>
                        </TouchableOpacity>
                    ))}
                    {/*<TouchableOpacity*/}
                    {/*    style={[styles.tab, isSplitOrder && styles.splitTab]}*/}
                    {/*    onPress={() => {*/}
                    {/*todo refactor it for bontott for now every order is "split" order so every order can be paid with multiple payment options*/}
                    {/*        handleSplitorderToggle()*/}
                    {/*    }}*/}
                    {/*>*/}
                    {/*    <View style={{ flexDirection: 'row' }}>*/}
                    {/*        <View style={{ flexDirection: 'column', paddingRight: "4px" }}>*/}
                    {/*            <Feather name={"dollar-sign"} size={10} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*            <Feather name={"credit-card"} size={10} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*        </View>*/}
                    {/*        <Feather name={"shuffle"} size={19} color={isSplitOrder ? 'white' : '#666'} />*/}
                    {/*    </View>*/}
                    {/*    <Text style={[styles.tabText, isSplitOrder && styles.activeSplitTabText]}>Bontott</Text>*/}
                    {/*</TouchableOpacity>*/}
                </View>

                    {/* Payment Amount Input */}
                    <View style={styles.amountContainer}>
                        {/*{isSplitOrder && <TouchableOpacity onPress={addPayment} >*/}
                        {/*    <Feather name={"plus-square"} size={28} color="black"/>*/}
                        {/*</TouchableOpacity>}*/}

                        <TouchableOpacity onPress={handleClear} style={styles.clearButton}>
                        <Feather name="trash-2" size={28} color="black" />
                        </TouchableOpacity>
                        <TextInput
                            style={styles.amountInput}
                            value={formatAmountWithSpaces(amount)}
                            editable={true}
                            keyboardType="numeric"
                            placeholder={'Kapott összeg'}
                            placeholderTextColor={'#AAA'}
                            onFocus={() => setAmount('')}
                            onChangeText={(text) => {
                                // Allow only numbers and one decimal point
                                const numericText = text.replace(/[^0-9.]/g, '');
                                setAmount(numericText);
                            }}
                        />
                        <TouchableOpacity onPress={handleBackspace} style={styles.backspaceButton}>
                        <Text style={styles.backspaceText}>⌫</Text>
                        </TouchableOpacity>
                    </View>

                    {/* Info Lines */}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.infoContainer}>
                        <View style={styles.infoRow}>
                            <Text style={styles.infoLabel}>Fizetendő:</Text>
                            <Text style={styles.infoValue}>{priceFormatter(originalAmount)}</Text>
                        </View>
                        <View style={[styles.infoRow, {
                            color: Number(amountToPay) > 0 ? "red" : "#333",
                            fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                        }]}>
                            {(amountToPay >= 1 && !isSplitOrder) && (<><Text style={[styles.infoLabel, {
                                color: Number(amountToPay) > 0 ? "red" : "#333",
                                fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                            }]}>Még fizetendő:</Text>
                                <Text style={[styles.infoValue, {
                                color: Number(amountToPay) > 0 ? "red" : "#333",
                                fontWeight: Number(amountToPay) > 0 ? "900" : "200"
                            }]}>{priceFormatter(amountToPay)}</Text></>)}
                            {( isSplitOrder && paymentsSum > 0 ) && (<><TouchableOpacity onPress={() => toggleModal()}><Text style={[styles.infoLabel, {
                                color: Math.round(paymentsSum) >= Math.round(originalAmount) ? "#3baa1a" : "#f1b34b",
                                fontWeight: "900",
                            }]}>Eddig fizetett:</Text></TouchableOpacity>
                                <TouchableOpacity style={{paddingLeft: "40px", paddingRight: "40px"}} onPress={() => toggleModal()}>
                                    <Feather name={"list"} size={20} color={Math.round(paymentsSum) >= Math.round(originalAmount) ? "#3baa1a" : "#f1b34b"}  />
                                </TouchableOpacity>
                                <TouchableOpacity onPress={() => toggleModal()}><Text style={[styles.infoValue, {
                                    color: Math.round(paymentsSum) >= Math.round(originalAmount) ? "#3baa1a" : "#f1b34b",
                                    fontWeight: "900",
                                }]}>{priceFormatter(paymentsSum)}</Text></TouchableOpacity></>)}
                    </View>
                        {Number(changeToReturn) > 1 && <View style={styles.infoRow}>
                            {paymentType === 'Bankkártya'  ?
                                Number(changeToReturn) > 1 && (<>
                                    <Text style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                        Túlfizetés:
                                    </Text>
                                    <Text style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 1 ? "900" : "200"}]}>
                                        {priceFormatter(changeToReturn)}
                                    </Text>
                                </>)
                                :
                                changeToReturn > 4 && <>
                                    <Text style={[styles.infoLabel, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                        Visszajáró:
                                    </Text>
                                    <Text style={[styles.infoValue, {fontWeight: Number(changeToReturn) > 4 ? "900" : "200"}]}>
                                        {priceFormatter(hungarianRoundNumbers(changeToReturn))}
                                    </Text>
                                </>
                            }


                        </View>}
                    </View>}

                <View style={styles.switchContainer}>
                    <Text style={styles.switchLabel}>ÁFÁ-s számla igénylése</Text>
                    <Switch
                        vibrate={true}
                        thumbColor="#f1b34b"
                        trackColor={{ false: "#767577", true: "#ecbb69" }}
                        ios_backgroundColor="#3e3e3e"
                        value={needInvoice}
                        onValueChange={handleSwitchChange}
                    />
                    <VatModal visible={modalVisible} onSubmit={setVatInvoiceData} onDismiss={handleModalDismiss} />
                </View>

                    {/* Pinpad and Process Payment Button Container */}
                    {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.bottomContainer}>
                        {/* Pinpad */}
                        <View style={styles.pinpadContainer}>
                            <PinPad onButtonClick={(value) => (value === 'X' ? addPayment() : handleInput(value.toString()))}/>
                        </View>

                        {/* Back and Process Payment Buttons */}
                        {(cartItems.length > 0 || alreadySentCartItems?.length > 0) && <View style={styles.buttonContainer}>
                            <TouchableOpacity style={styles.backButton} onPress={() => {
                                if(setIsModalVisible !== undefined) {
                                    setIsModalVisible(false)
                                }

                                navigation.navigate('HomeScreen');
                            }}>
                                <Feather name="arrow-left" size={24} color="#fff"/>
                            </TouchableOpacity>

                            {paymentsSum > originalAmount + 1 ? (
                                <>
                                    <TouchableOpacity style={styles.borravaloButton} onPress={() => {
                                        confirmPaymentAmount(true)
                                    }}>
                                        <Text style={styles.processButtonText}>Borravaló</Text>
                                    </TouchableOpacity>
                                    <TouchableOpacity style={styles.noHorizontalPaddingButton} onPress={()=>{confirmPaymentAmount(false)}}>
                                        <Text style={styles.processButtonText}>
                                            Visszajáró: {priceFormatter(hungarianRoundNumbers(isSplitOrder ? paymentsSum - originalAmount : changeToReturn))}
                                        </Text>
                                    </TouchableOpacity>
                                </>
                            ) : (
                                <TouchableOpacity style={[styles.processButton, {backgroundColor: ((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount -1))  ? "#f3a965" : "#ff8000"}]} disabled={((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount - 4))} onPress={confirmPaymentAmount}>
                                    {((!isSplitOrder && amountToPay > 1) || (isSplitOrder && paymentsSum < originalAmount - 4)) && <Text style={styles.processButtonText}>Még fizetendő: {isSplitOrder ? priceFormatter(originalAmount - paymentsSum):  priceFormatter(amountToPay)}</Text>}
                                    {canBeClosed() && <Text style={styles.processButtonText}>Nyugta zárása</Text>}
                                </TouchableOpacity>
                            )}
                        </View>}
                    </View>}
                {(isSplitOrder && paymentsModal) && (
                    <Modal
                        visible={paymentsModal}
                        transparent={true}
                        animationType="fade"
                        onRequestClose={toggleModal}
                    >
                        <View style={styles.modalBackdrop}>
                            <View style={styles.modalContainer}>
                                <View style={styles.modalHeader}>
                                    <Text style={styles.modalTitle}>Eddig fizetett</Text>
                                    <TouchableOpacity onPress={toggleModal}>
                                        <Feather name="x" size={24} color="#333" />
                                    </TouchableOpacity>
                                </View>

                                <FlatList
                                    data={payments}
                                    keyExtractor={(item, index) => index.toString()}
                                    renderItem={({ item, index }) => (
                                        <View style={styles.paymentItem}>
                                            <Text style={styles.paymentType}>{reverseConvertPaymentType(item.paymentType)}</Text>
                                            <Text style={styles.paymentAmount}>{priceFormatter(item.amount)}</Text>
                                            <TouchableOpacity onPress={() => handleDeletePayment(index)}>
                                                <Feather name="trash-2" size={22} color="red" />
                                            </TouchableOpacity>
                                        </View>
                                    )}
                                />

                                <TouchableOpacity style={styles.closeButton} onPress={toggleModal}>
                                    <Text style={styles.closeButtonText}>Bezárás</Text>
                                </TouchableOpacity>
                            </View>
                        </View>
                    </Modal>
                )}
                </LinearGradient>
            </View>
        );
    };

        const styles = StyleSheet.create({
            container: {
                flex: 1,
                backgroundColor: '#fff',
                justifyContent: 'space-between',
                zIndex: 1000,
            },
            splitTab: {
                backgroundColor: '#f1b34b',
            },
            activeSplitTabText: {
                color: 'white',
            },
            tabsContainer: {
                flexDirection: 'row',
                justifyContent: 'space-around',
                padding: 10,
                backgroundColor: '#f1f1f1',
            },
            tab: {
                flex: 1,
                padding: 12,
                alignItems: 'center',
            },
            activeTab: {
                borderBottomWidth: 2,
                borderBottomColor: '#f1b34b',
            },
            tabText: {
                fontSize: 16,
                color: '#666',
                marginTop: 4,
            },
            activeTabText: {
                color: '#f1b34b',
            },
            infoContainer: {
                marginTop: 20,
                padding: 10,
            },
            infoRow: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                marginVertical: 2,
            },
            infoLabel: {
                fontSize: 18,
                color: '#333',
                fontWeight: "500",
            },
            infoValue: {
                fontSize: 18,
                color: '#333',
                fontWeight: 'bold',
            },
            amountContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 7,
                width: '100%',
            },
            backspaceButton: {
                marginRight: 10,
                marginLeft: 5,
            },
            backspaceText: {
                fontSize: 26,
                color: '#333',
            },
            amountInput: {
                marginTop: 10,
                fontSize: 30,
                textAlign: 'center',
                color: '#333',
                borderBottomWidth: 1,
                width: width * 0.65,
            },
            clearButton: {
                marginLeft: 10,
                marginRight: 10,
            },
            clearText: {
                fontSize: 26,
                color: '#333',
            },
            bottomContainer: {
                flex: 1,
                justifyContent: 'flex-end',
                paddingBottom: 10,
            },
            pinpadContainer: {
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                width: "100%",
                height: height * 0.275,
                paddingHorizontal: 0,
                paddingBottom: 10,
                marginBottom: 5,
            },
            pinpadButton: {
                width: width / 3.1,
                height: (height * 0.275) / 4, // Divide the container height by 4 to get the button height
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: 5,
                borderColor: '#e8e8e8',
                borderWidth: 0.5
            },
            noHorizontalPaddingButton: {
                backgroundColor: '#F76E24',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 15,
                marginTop: 20,
                flex: 1,
                marginLeft: 0,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                paddingLeft: 3,
                paddingRight: 3,
            },
            borravaloButton: {
                backgroundColor: '#f1b34b',
                alignItems: 'center',
                paddingTop: 15,
                paddingBottom: 15,
                marginTop: 20,
                paddingLeft: 10,
                paddingRight: 10,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5,
                width: 'auto',
            },
            buttonContainer: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 16,
            },
            backButton: {
                backgroundColor: 'black',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 15,
                marginTop: 20,
                borderRadius: 5,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0
            },
            processButton: {
                backgroundColor: '#F76E24',
                alignItems: 'center',
                padding: 15,
                marginTop: 20,
                flex: 1,
                marginLeft: 0,
                borderBottomRightRadius: 5,
                borderTopRightRadius: 5
            },
            processButtonText: {
                fontSize: 20,
                color: '#fff',
                fontWeight: 'bold',
            },
            spinnerTextStyle: {
                color: '#FFF'
            },
            switchContainer: {
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
                paddingHorizontal: 16,
                marginVertical: 10,
            },
            switchLabel: {
                fontSize: 16,
                color: '#000',
            },
            modalBackdrop: {
                flex: 1,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                justifyContent: 'center',
                alignItems: 'center',
            },
            modalContainer: {
                width: width > 768 ? '30%' : '80%',
                backgroundColor: '#fff',
                borderRadius: 15,
                padding: 20,
                elevation: 10,
            },
            modalHeader: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottomWidth: 1,
                borderBottomColor: '#ddd',
                paddingBottom: 10,
                marginBottom: 10,
            },
            modalTitle: {
                fontSize: 20,
                fontWeight: 'bold',
                color: '#333',
            },
            paymentItem: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 12,
                borderBottomWidth: 1,
                borderBottomColor: '#eee',
            },
            paymentType: {
                fontSize: 16,
                color: '#444',
                flex: 1,
            },
            paymentAmount: {
                fontSize: 16,
                fontWeight: 'bold',
                color: '#333',
                flex: 1,
                textAlign: 'right',
                marginRight: 15,
            },
            closeButton: {
                marginTop: 20,
                padding: 12,
                backgroundColor: '#f1b34b',
                borderRadius: 8,
                alignItems: 'center',
            },
            closeButtonText: {
                fontSize: 18,
                fontWeight: 'bold',
                color: '#fff',
            },
        });

export default PaymentScreen;
import React, { useState, useEffect } from 'react';
import { Alert, Button, StyleSheet, Text, View } from 'react-native';
import { connect, useDispatch, useSelector } from 'react-redux';
import Accordion from 'react-native-collapsible/Accordion';
import { Checkbox } from 'react-native-paper';
import axios from "axios";
import { getAccessToken } from "../helpers/storage";
import { ApiConfig } from "../config/ApiConfig";
import { setPrinterEvents, setPrinters } from "../store/actions/terminal";
import notFoundEmojiAnimation from "../../assets/animations/not-found-emoji.json";
import LottieView from "lottie-react-native";
import { LinearGradient } from "expo-linear-gradient";

const SettingsPrinterPairing = (props) => {
    const [activeSections, setActiveSections] = useState([]);
    const [printerEvents, setPrinterEvents] = useState(props.printerEvents || []);
    //mock printers for testing just delete the "props." from the "props.printers" lines
     const [printers, setPrinters] = useState([
         { id: 1, macAddress: '00:11:22:33:44:55', deviceName: 'Printer 1', ip: '192.168.1.2', port: '9100' },
         { id: 2, macAddress: '66:77:88:99:AA:BB', deviceName: 'Printer 2', ip: '192.168.1.3', port: '9100' },
         { id: 3, macAddress: 'CC:DD:EE:FF:00:11', deviceName: 'Printer 3', ip: '192.168.1.4', port: '9100' }
     ]);

    useEffect(() => {
        _getPrinterEvents();
    }, []);

     useEffect(() => {
         //console.log("props.printerEvents redux:", props.printerEvents);
         //console.log("state.printerEvents", printerEvents);
             setPrinterEvents(props.printerEvents);
     }, [props.printerEvents]);


    const updatePrinterEvents = (newlyFetchedEvents) => {
        if(!newlyFetchedEvents || newlyFetchedEvents?.length < 1) return;
        const updatedEvents = newlyFetchedEvents.map(newEvent => {
            const existingEvent = props.printerEvents.find(event => event._id === newEvent._id);
            if (existingEvent) {
                return {
                    ...newEvent,
                    printers: existingEvent.printers
                };
            }
            return {
                ...newEvent,
                printers: []
            };
        });
        setPrinterEvents(updatedEvents);
        props.setPrinterEventsRedux(updatedEvents);
    };

    const _getPrinterEvents = async () => {
        const token = await getAccessToken();
        axios.get(ApiConfig.apiURL + "/api/restaurant/printerEvent/get", {
            headers: {
                "Accept-Language": "hu_HU",
                authorization: `Bearer ${token}`,
            },
        })
            .then(async function ({ data }) {
                updatePrinterEvents(data);
                //console.log("data....", data);
            })
            .catch((e) => {
                Alert.alert(
                    "Nem sikerült a profilok betöltése!",
                    "Próbáld újra majd vedd fel velünk a kapcsolatot, ha a hiba továbbra is fennáll!"
                );
                console.error(e);
            });
    };

    const handleTogglePrinterSelection = (eventId, printer) => {
        setPrinterEvents(prevEvents => {
            const updatedEvents = prevEvents.map(event => {
                if (event.id === eventId) {
                    const isPrinterSelected = event.printers.some(p => p.id === printer.id);
                    return {
                        ...event,
                        printers: isPrinterSelected
                            ? event.printers.filter(p => p.id !== printer.id)
                            : [...event.printers, printer]
                    };
                }
                return event;
            });
            //console.log("updatedEventstogg", updatedEvents);
            props.setPrinterEventsRedux(updatedEvents);
            return updatedEvents;
        });
    };

    const renderHeader = (section, _, isActive) => (
        <View style={[styles.header, isActive ? styles.active : styles.inactive]}>
            <Text style={styles.headerText}>{section.name}</Text>
        </View>
    );

    const renderContent = (section) => (
        <View style={styles.content}>
            {props.printers.map(printer => (
                <View key={printer.id} style={styles.printerItem}>
                    <Text style={styles.printerInfo}>
                        {printer.ip} ({printer.port} / {printer.macAddress})
                    </Text>
                    <Checkbox
                        status={section.printers.some(p => p.id === printer.id) ? 'checked' : 'unchecked'}
                        onPress={() => handleTogglePrinterSelection(section.id, printer)}
                    />
                </View>
            ))}
        </View>
    );

    return (
        <LinearGradient
            colors={['#EAE5EE', '#FEE6DE', '#EBE5F2']}
            style={{ flex: 1, width: '100%', height: '100%' }}
        >
            <View style={styles.container}>
                {props.printers.length === 0 ? (
                    <View style={styles.noPrintersFound}>
                        {/*todo: lottie not working on adndroid*/}
                        {/*<View style={styles.lottieContainer}>*/}
                        {/*    <LottieView*/}
                        {/*        source={notFoundEmojiAnimation}*/}
                        {/*        autoPlay*/}
                        {/*        loop*/}
                        {/*        style={styles.notFoundAnimation}*/}
                        {/*    />*/}
                        {/*</View>*/}
                        <View style={styles.noPrintersTextContainer}>
                            <Text style={styles.noPrintersTitle}>Nyomtatók nem találhatóak</Text>
                            <Button
                                title={"Nyomtatók felderítése"}
                                mode="contained"
                                color="#fca34c"
                                onPress={() => props.navigation.navigate("SettingsPrinterSearchScreen")}
                            >
                                Nyomtatók felderítése
                            </Button>
                        </View>
                    </View>
                ) : (
                    <Accordion
                        sections={printerEvents || []}
                        activeSections={activeSections}
                        renderHeader={renderHeader}
                        renderContent={renderContent}
                        onChange={setActiveSections}
                    />
                )}
            </View>
        </LinearGradient>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        padding: 10,
    },
    header: {
        padding: 10,
        fontWeight: 'bold',
        backgroundColor: '#fca34c',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    headerText: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    content: {
        padding: 10,
    },
    printerItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 5,
    },
    printerInfo: {
        flex: 1,
    },
    active: {
        backgroundColor: '#f3a965',
    },
    inactive: {
        backgroundColor: '#f4f4f4',
    },
    noPrintersFound: {
        alignItems: 'center',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    lottieContainer: {
        width: 80,
        height: 80,
    },
    notFoundAnimation: {
        width: '70px',
        height: '70px',
    },
    noPrintersTextContainer: {
        marginTop: 20,
    },
    noPrintersTitle: {
        fontSize: 18,
        fontWeight: 'bold',
    },
    buttonContainer: {
        marginTop: 20,
    },
});

function mapStateToProps(state) {
    return {
        printerEvents: state.device.printerEvents,
        printers: state.device.printers
    };
}

function mapDispatchToProps(dispatch) {
    return {
        setPrinterEventsRedux: (printerEvents) =>
            dispatch(setPrinterEvents(printerEvents)),
        setPrintersRedux: (printers) => dispatch(setPrinters(printers)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPrinterPairing);
import { Alert, Linking } from 'react-native';

// Configuration constants (customize as needed)
const APP_ID = 'org.happysolutions.app';
const CALLBACK_SCHEME = 'happy-services';

const PIN_CODE = '111111';

const ISV_SOURCE_CODE = '8486';
const ISV_CURRENCY_CODE = 348;
const ISV_CUSTOMER_TRNS = 'ItemDescription';

const ISV_CLIENT_TRANSACTION_ID = '12345678901234567890123456789012';

const SOURCE_TERMINAL_ID = '16009276';
const API_KEY = 'i1mj2tpgwnx9r1x95yph06c20b80fiskj09dh0mv4y801.apps.vivapayments.com';
const API_SECRET = 'gg4a1p35G6eDV2LY8108qrkb9MOXB';

const ISV_CLIENT_ID = '311v1doe75h591mnt0p5xz0tndzp2kghe0abvn9rejg95.apps.vivapayments.com';
const ISV_CLIENT_SECRET = 'gg4a1p35G6eDV2LY8108qrkb9MOXB';

const ISV_MERCHANT_ID = '6b278a43-bc78-4963-b91b-a84048e1bc4a';

const ISV_MERCHANT_SOURCE_CODE = '1813';

const VivaPaymentsModule = {
    async initiatePayment(amount, tipAmount = 0) {
        const CLIENT_TRANSACTION_ID = Date.now().toString(); // Generate unique transaction ID

        const paymentUrl = `vivapayclient://pay/v1`
            + `?appId=${APP_ID}`
            + "&action=sale"
            + "&clientTransactionId="+CLIENT_TRANSACTION_ID
            + "&amount="+amount
            + "&tipAmount="+tipAmount
            + "&show_receipt=true"
            + "&show_transaction_result=false"
            + "&show_rating=false"
            //+ "&ISV_amount="+(((amount - tipAmount) / 100) * 1.2)
            //+ "&ISV_clientId="+ISV_CLIENT_ID
            //+ "&ISV_clientSecret="+ISV_CLIENT_SECRET
            //+ "&ISV_sourceCode="+ISV_SOURCE_CODE
            //+ "&ISV_currencyCode="+ISV_CURRENCY_CODE
            //+ "&ISV_customerTrns=ItemDescription"
            //+ "&ISV_clientTransactionId="+CLIENT_TRANSACTION_ID
            //+ "&ISV_merchantId="+ISV_MERCHANT_ID
            //+ "&ISV_merchantSourceCode="+ISV_MERCHANT_SOURCE_CODE
            + "&paymentMethod=CardPresent"
            + `&callback=${CALLBACK_SCHEME}://payment/result`;

        console.log("paymentUrl: " + paymentUrl);

        // Attempt to open the URL
        const supported = await Linking.canOpenURL(paymentUrl);
        if (supported) {
            await Linking.openURL(paymentUrl);
        } else {
            Alert.alert('Váratlan hiba történt', 'Nem sikerült a Viva alkalmazás megnyitása, kérjük próbálja meg kézzel fizettetni!\n\nHibaüzenet: ' + JSON.stringify(supported));
        }
    },

    // Call this function in the main app to handle callback URL
    handleCallbackUrl(url) {
        const resultUri = new URL(url);

        const status = resultUri.searchParams.get('status')
        const message = resultUri.searchParams.get('message')
        const amount = resultUri.searchParams.get('amount')
        const cardType = resultUri.searchParams.get('cardType')
        const accountNumber = resultUri.searchParams.get('accountNumber')

        Alert.alert('Tranzakció státusza: ' + status, message || 'No details received.');
        return { url, responseData: {status, message, amount, cardType, accountNumber} };
    }
};

export default VivaPaymentsModule;